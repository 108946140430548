import { fieldTrackerAPI } from './base';

export const GET_CUSTOMERS_QUERY = async (): Promise<any> => {
  return fieldTrackerAPI.get('/customers');
};

export const GET_CUSTOMER_BY_ID = async (id: string): Promise<any> => {
  return fieldTrackerAPI.get(`/customers/${id}`);
};

export const CREATE_CUSTOMER = async (customerPayload: {
  password: string;
  roles: string[];
  email: string;
  address: string;
  name: string;
  description?: string;
  merchant?: { id: string };
}): Promise<any> => {
  return fieldTrackerAPI.post('/customers', customerPayload);
};

export const DELETE_CUSTOMER = async (id: string): Promise<any> => {
  return fieldTrackerAPI.delete(`/customers/${id}`);
};
