import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Agent } from 'services/api/interface/response/Agent';
import { CampaignResponse } from 'services/api/interface/response/Campaign';
import { CampaignAgentLocation } from 'services/api/interface/response/CampaignAgentLocation';
import { CampaignForm } from 'services/api/interface/response/CampaignForm';
import { CampaignResult } from 'services/api/interface/response/CampaignResult';

// Define the shape of our context
interface CampaignContextType {
  campaign: CampaignResponse | null;
  setCampaign: (campaign: CampaignResponse) => void;
  updateAgent: (agentId: string, updatedAgent: Partial<Agent>) => void;
  updateForm: (updatedForm: Partial<CampaignForm>) => void;
  updateResults: (updatedResults: CampaignResult) => void;
  updateAgentLocation: (
    locationId: string,
    updatedLocation: CampaignAgentLocation
  ) => void;
}

// Create the context
const CampaignContext = createContext<CampaignContextType | undefined>(
  undefined
);

// Create a provider component
export const CampaignProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [campaign, setCampaign] = useState<CampaignResponse | null>(null);
  console.log(campaign);
  const updateAgent = (agentId: string, updatedAgent: Partial<Agent>) => {
    if (campaign) {
      const updatedAgents = campaign?.agents?.map(agent =>
        agent.id === agentId ? { ...agent, ...updatedAgent } : agent
      ) as unknown as [Agent];
      setCampaign({ ...campaign, agents: updatedAgents });
    }
  };

  const updateForm = (updatedForm: Partial<CampaignForm>) => {
    if (campaign) {
      setCampaign({
        ...campaign,
        campaignForms: [{ ...campaign.campaignForms, ...updatedForm }]
      });
    }
  };

  const updateResults = (updatedResults: CampaignResult) => {
    if (campaign) {
      setCampaign({
        ...campaign,
        campaignResults: [{ ...campaign.campaignResults, ...updatedResults }]
      });
    }
  };

  const updateAgentLocation = (
    locationId: string,
    updatedLocation: CampaignAgentLocation
  ) => {
    if (campaign) {
      const updatedLocations = campaign.campaignAgentLocations.map(location =>
        location.id === locationId
          ? { ...location, ...updatedLocation }
          : location
      ) as unknown as [CampaignAgentLocation];
      setCampaign({ ...campaign, campaignAgentLocations: updatedLocations });
    }
  };

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        setCampaign,
        updateAgent,
        updateForm,
        updateResults,
        updateAgentLocation
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

// Create a custom hook to use the campaign context
export const useCampaign = () => {
  const context = useContext(CampaignContext);
  if (context === undefined) {
    throw new Error('useCampaign must be used within a CampaignProvider');
  }
  return context;
};

// Example of how to fetch and set campaign data (to be used outside the context)
export const fetchCampaignData = async (
  campaignId: string
): Promise<CampaignResponse> => {
  // Replace this with your actual API call
  const response = await fetch(`/api/campaigns/${campaignId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch campaign data');
  }
  return response.json();
};
