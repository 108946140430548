import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const defaultBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Page 1',
    url: '#!'
  },
  {
    label: 'Page 2',
    url: '#!'
  },
  {
    label: 'Default',
    active: true
  }
];

export const merchantBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'All Merchants',
    url: '/admin/merchant'
  },
  {
    label: 'Merchant details',
    active: true
  }
];

export const agentBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'All Agents',
    url: '/admin/agents'
  },
  {
    label: 'Agent details',
    active: true
  }
];

export const clientBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'All Clients',
    url: '/admin/clients'
  },
  {
    label: 'Create Client',
    active: true
  }
];

export const viewClientBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'All Clients',
    url: '/admin/clients'
  },
  {
    label: 'Client details',
    active: true
  }
];

export const MclientBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'All Clients',
    url: '/merchant/clients'
  },
  {
    label: 'Create Client',
    active: true
  }
];

export const MviewClientBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'All Clients',
    url: '/merchant/clients'
  },
  {
    label: 'Client details',
    active: true
  }
];

export const jobBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Job Dashboard',
    url: '/home'
  },
  {
    label: 'Create Job',
    active: true
  }
];

export const ecomBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Fashion',
    url: '#!'
  },
  {
    label: 'Womens Fashion',
    url: '#!'
  },
  {
    label: 'Footwear',
    url: '#!'
  },
  {
    label: 'Hills',
    active: true
  }
];
