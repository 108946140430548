import React, { useEffect, useState } from 'react';
import HumanReadableTime from 'components/utils/HumanReadableTime';
import { Placeholder, Table, Card, Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  GET_CAMPAIGN_AGENT,
  GET_CAMPAIGN_BY_ID,
  GET_CAMPAIGN_RESULT_BY_ID
} from 'services/api/campaign';
import { GET_AGENT_BY_ID } from 'services/api/agent';
import { convertCoordinatesToPlaceWithGoogle } from 'helpers/utils';
import { GET_CUSTOMER_BY_ID } from 'services/api/customer';
import Button from 'components/base/Button';
import { GET_MERCHANT_BY_ID } from 'services/api/merchant';
import useAdvanceTable from 'hooks/useAdvanceTable';
import CampaignAgentTable, {
  campaignAgentTableColumns
} from 'components/tables/survey-app-table/campaignTable/CampaignAgentTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

const AdminViewCampaignRes = () => {
  const { id } = useParams<{ id?: string }>();
  const [header, setHeader] = useState<Array<string>>([]);
  const [resultData, setResultData] = useState<Array<any>>([]);
  const [placeNames, setPlaceNames] = useState<{ [key: string]: string }>({});
  const [showResults, setShowResults] = useState(false);

  const { data: campaign, isFetching: campaignFetching } = useQuery(
    ['getCampaignDetails', id],
    () => (id ? GET_CAMPAIGN_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const { data: campaignAgents, isLoading: loadingAgents } = useQuery(
    ['getCampaignAgent', id],
    () => (id ? GET_CAMPAIGN_AGENT(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const customerId = campaign?.data?.customer?.id;
  const merchantId = campaign?.data?.merchant?.id;

  const { data: customer } = useQuery(
    ['getCustomerDetails', customerId],
    () => (customerId ? GET_CUSTOMER_BY_ID(customerId) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const { data: merchant } = useQuery(
    ['getMerchantDetails', merchantId],
    () => (merchantId ? GET_MERCHANT_BY_ID(merchantId) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const { data: campaignResults, isFetching: campaignFormFetching } = useQuery(
    ['getCampaignForm', id],
    () => (id ? GET_CAMPAIGN_RESULT_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const agentId = campaignResults?.data[0]?.agent?.id;

  const { data: agent } = useQuery(
    ['getCampaignForm', agentId],
    () => (agentId ? GET_AGENT_BY_ID(agentId) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  useEffect(() => {
    if (
      campaignResults &&
      campaignResults.data &&
      campaignResults.data.length > 0
    ) {
      const firstResult = campaignResults.data[0].formData;
      if (firstResult) {
        const headers = Object.keys(firstResult).filter(
          (_: any, index: number) => index % 2 === 0
        );
        setHeader([...headers, 'Created At', 'Location Stamp']);
      }

      setResultData(campaignResults.data);
    }
  }, [campaignResults]);

  useEffect(() => {
    async function fetchPlaceNames() {
      const names: { [key: string]: string } = {};
      for (const result of resultData) {
        const [latitude, longitude] = result.locationStamp
          .split(',')
          .map(Number);
        names[result.id] = await convertCoordinatesToPlaceWithGoogle(
          latitude,
          longitude
        );
      }
      setPlaceNames(names);
    }

    fetchPlaceNames();
  }, [resultData]);

  const table = useAdvanceTable({
    data: campaignAgents?.data || [],
    columns: campaignAgentTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  const renderTable = () => {
    if (!header.length || !resultData.length)
      return <>This Campaign has no Form Submission yet</>;

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Agent Name</th>
            {header.map(head => (
              <th key={head}>{head.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {resultData.map((result, index) => (
            <tr key={result.id} className="capitalize">
              <td>{index + 1}</td>
              <td>{agent?.data?.name}</td>

              {header.map(headerItem => (
                <td key={headerItem}>
                  {headerItem === 'Created At' ? (
                    <HumanReadableTime date={new Date(result.createdAt)} />
                  ) : headerItem === 'Location Stamp' ? (
                    <span>{placeNames[result.id] || result.locationStamp}</span>
                  ) : (
                    result.formData[`value${headerItem}`] || ''
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div>
      {!showResults ? (
        <div>
          {campaignFetching ? (
            <div className="g-3 mt-8 mb-8">
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px'
                }}
              >
                <h2 className="mb-5">{campaign?.data?.name} Campaign</h2>
                <Button
                  variant="secondary"
                  onClick={() => setShowResults(true)}
                >
                  View Campaign Results
                </Button>
              </div>
              <Card className="mb-5 mb-xxl-7">
                <Card.Body>
                  <Row className="gy-5">
                    <Col md={6} className="d-flex justify-content-between">
                      <div>
                        <h4>Description</h4>
                        <p className="lh-sm mb-0 text-700">
                          {campaign?.data?.description}
                        </p>
                      </div>
                      <div className="my-4 mx-3 border-start d-none d-md-block"></div>
                    </Col>
                    <Col md={6}>
                      <h4>Merchant</h4>

                      <p className="lh-sm mb-0 text-700">
                        {merchant?.data?.name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <h4>Customer</h4>

                      <p className="lh-sm mb-0 text-700">
                        {customer?.data?.name}
                      </p>
                    </Col>
                    <Col md={6}>
                      <h4>Date Created</h4>

                      <p className="lh-sm mb-0 text-700">
                        <HumanReadableTime
                          date={new Date(campaign?.data?.createdAt as string)}
                        />
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div>
                <AdvanceTableProvider {...table}>
                  {loadingAgents ? (
                    <div className="g-3 mt-8 mb-8">
                      <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                      <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </div>
                  ) : (
                    <CampaignAgentTable />
                  )}
                </AdvanceTableProvider>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          {campaignFormFetching ? (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          ) : (
            <div
              style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}
            >
              {renderTable()}
              <Button
                variant="primary"
                onClick={() => setShowResults(false)}
                style={{ width: '50%' }}
              >
                Back to Campaign Details
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminViewCampaignRes;
