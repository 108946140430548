/* eslint-disable @typescript-eslint/no-explicit-any */
import { fieldTrackerAPI } from './base';

export const GET_ALL_MERCHANTS = async (): Promise<any> => {
  return fieldTrackerAPI.get('/merchants');
};

export const GET_MERCHANT_BY_ID = async (id: string): Promise<any> => {
  return fieldTrackerAPI.get(`/merchants/${id}`);
};

export const CREATE_MERCHANT = async (merchantPayload: {
  password: string;
  roles: string[];
  email: string;
  contactNumber: string;
  location: string;
  website?: string;
  name: string;
  description?: string;
}): Promise<any> => {
  return fieldTrackerAPI.post('/merchants', merchantPayload);
};

export const UPDATE_MERCHANT = async (
  id: string,
  merchantPayload: {
    password?: string;
    roles?: string[];
    email?: string;
    contactNumber?: string;
    location?: string;
    website?: string;
    name?: string;
    description?: string;
  }
): Promise<any> => {
  return fieldTrackerAPI.put(`/merchants/${id}`, merchantPayload);
};

export const DELETE_MERCHANT = async (id: string): Promise<any> => {
  return fieldTrackerAPI.delete(`/merchants/${id}`);
};
