import { Nav } from 'react-bootstrap';
import {
  RouteItems,
  adminRoutes,
  agentRoutes,
  customerRoutes,
  merchantRoutes
} from 'sitemap';
import { useAuthContext } from 'providers/AppProvider';
import { Link } from 'react-router-dom';

const NavbarTopNav = () => {
  const { role } = useAuthContext();

  const roleRoutes = {
    admin: adminRoutes,
    merchant: merchantRoutes,
    agent: agentRoutes,
    customer: customerRoutes
  };

  let userRole: 'admin' | 'merchant' | 'agent' | 'customer';
  if (role === 'admin') {
    userRole = 'admin';
  } else if (role === 'merchant') {
    userRole = 'merchant';
  } else if (role === 'agent') {
    userRole = 'agent';
  } else if (role === 'customer') {
    userRole = 'customer';
  } else {
    userRole = 'admin';
  }

  const routes = roleRoutes[userRole];

  return (
    <Nav className="navbar-nav-top pb-4 pb-lg-0 scrollbar">
      {routes.map(route => (
        <NavbarTopNavItem route={route} key={route.label} />
      ))}
    </Nav>
  );
};

const NavbarTopNavItem = ({ route }: { route: RouteItems }) => {
  return (
    <>
      {route.pages.map(
        page =>
          page.path && (
            <Nav.Item key={page.path} className="mx-2">
              <Link to={page.path} className="nav-link">
                {page.name}
              </Link>
            </Nav.Item>
          )
      )}
    </>
  );
};

export default NavbarTopNav;
