import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Dropdown } from 'react-bootstrap';
import { DELETE_MERCHANT } from 'services/api/merchant';
import { Merchant } from 'services/api/interface/response/Merchant';

const handleDelete = async (merchantId: string) => {
  try {
    await DELETE_MERCHANT(merchantId);
    window.location.reload();
  } catch (error) {
    console.error('Error deleting customer:', error);
  }
};

export const merchantTableColumns: ColumnDef<Merchant>[] = [
  {
    accessorKey: 'company',
    header: 'Organization/business',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <div className="d-flex align-items-center">
          <div>{name}</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'contactNumber',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="phone"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Phone</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { contactNumber } = original;
      return (
        <Link className="text-1000" to={`tel:${contactNumber}`}>
          {contactNumber}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'address',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-info-100 rounded me-2">
            <FeatherIcon
              icon="user"
              size={16}
              className="text-info-600 dark__text-info-300"
            />
          </div>
          <span>Address</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => original.location,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },
  {
    accessorKey: 'website',
    header: 'Website',
    cell: ({ row: { original } }) => original.website,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 text-700'
      }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="email"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Email</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const merchant = original?.user?.email;
      return (
        <Link to={`mailto:${merchant}`} className="text-1000">
          {merchant}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: ({ row: { original } }) => {
      const { id } = original;

      const navigate = useNavigate();
      const handleview = () => {
        return navigate(`/admin/view-merchant/${id}`);
      };

      const handleDeleteClick = async (id: string) => {
        try {
          await handleDelete(id);
        } catch (error) {
          console.error('Error handling delete:', error);
        }
      };
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item eventKey="1" onClick={handleview}>
              View
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              eventKey="4"
              className="text-danger"
              onClick={() => handleDeleteClick(id)}
            >
              Delete
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  }
];

const MerchantTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default MerchantTable;
