import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useState } from 'react';
import { Col, Offcanvas, Placeholder, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CampaignResponse } from 'services/api/interface/response/Campaign';
import { GET_ALL_CAMPAIGN } from 'services/api/campaign';
import AdminCampaignTable, {
  adminCampaignTableColumns
} from 'components/tables/survey-app-table/AdminCampaignTable';

const Campaign = () => {
  const [show, setShow] = useState(false);

  const {
    data: campaign,
    isLoading,
    isError
  } = useQuery('getadminCampaigns', GET_ALL_CAMPAIGN, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const handleClose = () => setShow(false);
  const table = useAdvanceTable({
    data: (campaign?.data as CampaignResponse[]) || [],
    columns: adminCampaignTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <div className="mb-6">
        {isError && <>Error</>}
        <AdvanceTableProvider {...table}>
          <h2 className="mb-4">{campaign?.data?.length} Campaign</h2>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <Link to={'/admin/create-campaign'}>
                <Button variant="primary" className="me-4">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Create campaigns
                </Button>
              </Link>
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Search by name"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          {isLoading ? (
            <div className="g-3 mt-8 mb-8">
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </div>
          ) : (
            <AdminCampaignTable />
          )}
        </AdvanceTableProvider>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Agents</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{/* <CreateAgent /> */}</Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Campaign;
