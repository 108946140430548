import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo
} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { Agent } from 'services/api/interface/response/Agent';
import debounce from 'lodash.debounce';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const LIBRARIES: 'places'[] = ['places'];
const CACHE_EXPIRATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

interface AdminAgentModalProps {
  show: boolean;
  isAssigning: boolean;
  handleClose: () => void;
  data?: Agent;
  setAgentSelectedPlace: React.Dispatch<
    React.SetStateAction<SelectedPlaceInterface | null>
  >;
}

interface CachedPlace {
  place: google.maps.places.PlaceResult;
  timestamp: number;
  localGovernmentArea: string | null;
}

export interface SelectedPlaceInterface extends google.maps.places.PlaceResult {
  localGovernmentArea: string | null;
}

const PlacesSearchModal: React.FC<AdminAgentModalProps> = ({
  show,
  isAssigning,
  handleClose,
  data,
  setAgentSelectedPlace
}) => {
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [localGovernmentArea, setLocalGovernmentArea] = useState<string | null>(
    null
  );
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY as string,
    libraries: LIBRARIES
  });

  const extractLocalGovernmentArea = useCallback(
    (addressComponents: google.maps.GeocoderAddressComponent[]) => {
      return (
        addressComponents.find(component =>
          component.types.includes('administrative_area_level_2')
        )?.long_name || null
      );
    },
    []
  );

  const cachePlace = useCallback(
    (place: google.maps.places.PlaceResult, lga: string | null) => {
      const key = `place_${place.place_id}`;
      const cachedPlace: CachedPlace = {
        place,
        timestamp: Date.now(),
        localGovernmentArea: lga
      };
      localStorage.setItem(key, JSON.stringify(cachedPlace));
    },
    []
  );

  const getCachedPlace = useCallback((placeId: string): CachedPlace | null => {
    const key = `place_${placeId}`;
    const cachedPlaceJson = localStorage.getItem(key);
    if (cachedPlaceJson) {
      const cachedPlace: CachedPlace = JSON.parse(cachedPlaceJson);
      if (Date.now() - cachedPlace.timestamp < CACHE_EXPIRATION) {
        return cachedPlace;
      }
      localStorage.removeItem(key);
    }
    return null;
  }, []);

  const handlePlaceSelection = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const cachedPlace = getCachedPlace(place.place_id as string);
      if (cachedPlace) {
        setSelectedPlace(cachedPlace.place);
        setLocalGovernmentArea(cachedPlace.localGovernmentArea);
      } else {
        setSelectedPlace(place);
        const lga = extractLocalGovernmentArea(
          place.address_components as google.maps.GeocoderAddressComponent[]
        );
        setLocalGovernmentArea(lga);
        cachePlace(place, lga);
      }
    },
    [extractLocalGovernmentArea, getCachedPlace, cachePlace]
  );

  const debouncedPlacesChanged = useMemo(
    () =>
      debounce(() => {
        if (searchBox) {
          const places = searchBox.getPlaces();
          if (places && places.length > 0) {
            handlePlaceSelection(places[0]);
          }
        }
      }, 300),
    [searchBox, handlePlaceSelection]
  );

  const onLoad = useCallback((ref: google.maps.places.SearchBox) => {
    setSearchBox(ref);
  }, []);

  const handleAssignment = useCallback(() => {
    setAgentSelectedPlace({ ...selectedPlace, localGovernmentArea });
  }, [selectedPlace, localGovernmentArea, setAgentSelectedPlace]);

  useEffect(() => {
    if (isLoaded && searchInputRef.current) {
      const pacContainers = document.getElementsByClassName('pac-container');
      Array.from(pacContainers).forEach(container => {
        (container as HTMLElement).style.zIndex = '1500';
      });
    }
  }, [isLoaded, show]);

  useEffect(() => {
    return () => {
      debouncedPlacesChanged.cancel();
    };
  }, [debouncedPlacesChanged]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Location to {data?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoaded ? (
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={debouncedPlacesChanged}
          >
            <Form.Control
              ref={searchInputRef}
              type="text"
              placeholder="Search for a place"
            />
          </StandaloneSearchBox>
        ) : (
          <div>Loading...</div>
        )}
        {selectedPlace && (
          <div className="mt-3 mb-3 card p-3">
            <h5>Selected Place:</h5>
            <p>Name: {selectedPlace.name}</p>
            <p>Address: {selectedPlace.formatted_address}</p>
            {localGovernmentArea && (
              <p>Local Government Area: {localGovernmentArea}</p>
            )}
            <p>
              Lat:{' '}
              {typeof selectedPlace.geometry?.location?.lat === 'function'
                ? selectedPlace.geometry?.location?.lat()
                : `${selectedPlace.geometry?.location?.lat}`}
            </p>
            <p>
              Long:{' '}
              {typeof selectedPlace.geometry?.location?.lng === 'function'
                ? selectedPlace.geometry?.location?.lng()
                : `${selectedPlace.geometry?.location?.lng}`}
            </p>
            <Button
              variant="success"
              onClick={handleAssignment}
              disabled={isAssigning}
            >
              Assign Location
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
      <style>{`
        .pac-container {
          z-index: 1500 !important;
        }
      `}</style>
    </Modal>
  );
};

export default React.memo(PlacesSearchModal);
