/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { clientBreadcrumbItems } from 'data/commonData';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { CREATE_CUSTOMER } from 'services/api/customer';
import { useMutation, useQuery } from 'react-query';
import { setToken } from 'services/api/base';
import { GET_ALL_MERCHANTS } from 'services/api/merchant';

interface Option {
  value: any;
  label: string;
}

const CreateClient = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    description: '',
    merchantId: ''
  });

  const { data: merchant } = useQuery('getAllMerchants', GET_ALL_MERCHANTS, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const createClientMutation = useMutation('createClient', CREATE_CUSTOMER, {
    onSuccess: data => {
      setIsSuccessful(true);
      console.log('Client creation successful:', data);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      setToken(token);
      setFormData({
        name: '',
        email: '',
        address: '',
        description: '',
        merchantId: '' // Clear merchantId after successful creation
      });
      navigate('/admin/clients');
    },
    onError: (error: any) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred');
      }
      console.error('Client creation error:', error);
    }
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { id, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [id]: value
    }));
  };

  const merchantOptions: Option[] = merchant?.data
    ? merchant.data.map((merchant: { id: any; name: any }) => ({
        value: merchant.id,
        label: merchant.name
      }))
    : [];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { merchantId, ...payload } = formData;
    const customerPayload = {
      ...payload,
      password: 'password',
      roles: ['customer'],
      merchant: { id: formData.merchantId }
    };
    setValidated(true);
    console.log(customerPayload);

    try {
      await createClientMutation.mutate(customerPayload);
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  return (
    <div className="mb-9">
      <PageBreadcrumb items={clientBreadcrumbItems} />
      <div className="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
        <Row>
          <Col xl={9}>
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-4">Create a new client</h2>
              <div className="d-flex mb-3">
                <Link to={'/admin/clients'}>
                  <Button variant="phoenix-primary" className="me-2 px-6">
                    Cancel
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xl={9}>
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <h4 className="mb-3">Client Information</h4>
            <Row className={'g-3 mb-5'}>
              <Col sm={6} md={6}>
                <FloatingLabel controlId="name" label="Client Name">
                  <Form.Control
                    type="text"
                    placeholder="Precious Organization"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={6}>
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    type="email"
                    placeholder="precious@gmail.com"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={6}>
                <FloatingLabel controlId="address" label="Address">
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={6}>
                <FloatingLabel controlId="merchantId" label="Select Merchant">
                  <Form.Control
                    as="select"
                    required
                    onChange={handleChange}
                    value={formData.merchantId}
                  >
                    <option value="">Select Merchant</option>
                    {merchantOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                </FloatingLabel>
              </Col>
            </Row>
            <h4 className="mb-3">Description</h4>
            <FloatingLabel controlId="description" label="Client Description">
              <Form.Control
                as="textarea"
                style={{ height: '128px' }}
                type="text"
                placeholder="Client description"
                value={formData.description}
                onChange={handleChange}
              />
            </FloatingLabel>
            <div className="text-end">
              <Button
                variant="primary"
                type="submit"
                className="mt-6"
                disabled={createClientMutation.isLoading}
              >
                {createClientMutation.isLoading
                  ? 'Creating...'
                  : 'Create Client'}
              </Button>
            </div>
            {isSuccessful && (
              <p className="text-success">Client created successfully!</p>
            )}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateClient;
