import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { Dropdown } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { DELETE_CAMPAIGN } from 'services/api/campaign';
import { CampaignResponse } from 'services/api/interface/response/Campaign';
import HumanReadableTime from './../../../utils/HumanReadableTime';
import { countWords } from 'helpers/utils';
import { Link } from 'react-router-dom';

const handleDelete = async (merchantId: string) => {
  try {
    await DELETE_CAMPAIGN(merchantId);
  } catch (error) {
    console.error('Error deleting customer:', error);
  }
};

export const campaignTableColumns: ColumnDef<CampaignResponse>[] = [
  {
    accessorKey: 'name',
    header: 'Campaign Name',
    cell: ({ row: { original } }) => {
      const { name, id } = original;
      return (
        <div className="d-flex align-items-center">
          <Link to={`/merchant/campaign/results/${id}`}>
            <div>{name}</div>
          </Link>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'customerName',
    header: 'Customer',
    cell: ({ row: { original } }) => {
      const { name: customerName } = original.customer
        ? original.customer
        : {
            name: 'Super Customer'
          };
      return (
        <div className="d-flex align-items-center">
          <div>{customerName}</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'description',
    header: 'Description',
    cell: ({ row: { original } }) => {
      const description = original.description;
      return (
        <div className="d-flex align-items-center">
          <div>{countWords(description, 3)}...</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'agents',
    header: 'Total Agent Count',
    cell: ({ row: { original } }) => {
      const agents = original?.agents;
      return (
        <div className="d-flex align-items-center">
          <div>{agents?.length}</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'createdAt',
    header: 'Created At',
    cell: ({ row: { original } }) => {
      const createdAt = original.createdAt;
      return (
        <div className="d-flex align-items-center">
          <div>
            <HumanReadableTime date={new Date(createdAt)} />
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },

  {
    id: 'leadDropdown',
    cell: ({ row: { original } }) => {
      const { id } = original;
      const queries = useQueryClient();
      const handleDeleteClick = async (id: string) => {
        try {
          await handleDelete(id);
          queries.invalidateQueries('getCampaigns');
        } catch (error) {
          console.error('Error handling delete:', error);
        }
      };
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item
              eventKey="4"
              className="text-danger"
              onClick={() => handleDeleteClick(id.toString())}
            >
              Delete
            </Dropdown.Item>
            {!original?.agents?.length && (
              <Dropdown.Item eventKey="4" className="text-danger">
                <Link to={`/merchant/campaign/${original.id}/add-agent`}>
                  Add Agent
                </Link>
              </Dropdown.Item>
            )}
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  }
];

const CampaignTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default CampaignTable;
