import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Dropdown } from 'react-bootstrap';
import { DELETE_AGENT } from 'services/api/agent';
import { Agent } from 'services/api/interface/response/Agent';

const handleDelete = async (agentId: string) => {
  try {
    await DELETE_AGENT(agentId);
    window.location.reload();
  } catch (error) {
    console.error('Error deleting customer:', error);
  }
};

export const agentTableColumns: ColumnDef<Agent>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <div className="d-flex align-items-center">
          <div>{name}</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="phone"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Phone</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return (
        <Link className="text-1000" to={`tel:${phone}`}>
          {phone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="email"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Email Address</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const email = original?.user?.email;
      return (
        <Link className="text-1000" to={`mailto:${email}`}>
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'merchant',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="user"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Merchant</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const merchant = original?.merchant?.name;
      return <p className="text-1000">{merchant}</p>;
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: ({ row: { original } }) => {
      const { id } = original;

      const handleDeleteClick = async (id: string) => {
        try {
          await handleDelete(id);
        } catch (error) {
          console.error('Error handling delete:', error);
        }
      };
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item
              eventKey="4"
              className="text-danger"
              onClick={() => handleDeleteClick(id.toString())}
            >
              Delete
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  }
];

const AgentTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default AgentTable;
