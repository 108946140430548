import React, { createContext, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { setToken } from 'services/api/base';

interface AccessTokenContextProps {
  accessToken: string;
  updateAccessToken: (newToken: string) => void;
}

const AccessTokenContext = createContext<AccessTokenContextProps | undefined>(
  undefined
);

export const AccessTokenProvider = ({ children }: any) => {
  const [cookies, setCookies] = useCookies(['accessToken']);
  const [accessToken, setAccessToken] = useState(() => {
    return cookies.accessToken || '';
  });

  const updateAccessToken = (newToken: string) => {
    if (newToken && newToken != '') {
      setAccessToken(newToken);
      setCookies('accessToken', setToken(cookies?.accessToken));
      setCookies('accessToken', newToken, {
        path: '/',
        maxAge: 86400,
        secure:
          process.env.NODE_ENV && process.env.NODE_ENV === 'production'
            ? true
            : false,
        sameSite: 'strict'
      });
    }
  };

  return (
    <AccessTokenContext.Provider value={{ accessToken, updateAccessToken }}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export const useAccessToken = (): AccessTokenContextProps => {
  const context = useContext(AccessTokenContext);
  if (!context) {
    throw new Error(
      'useAccessToken must be used within an AccessTokenProvider'
    );
  }
  return context;
};
