import { getItemFromStore } from 'helpers/utils';
import { fieldTrackerAPI } from '../base';
import { CampaignResponse } from '../interface/response/Campaign';
import { User } from '../interface/response/User';
import {
  AssignAgentLocationDto,
  CampaignRequest,
  UpdateAssignAgentLocationDto
} from '../interface/request/CampaignRequest';
import { isMerchant } from '../helper';
import { AxiosResponse } from 'axios';
import { CampaignAgentLocation } from '../interface/response/CampaignAgentLocation';

export const GET_ALL_MERCHANT_CAMPAIGN = async (): Promise<
  AxiosResponse<CampaignResponse[] | [] | undefined>
> => {
  const authUser = isMerchant() as User;
  return fieldTrackerAPI.get(`/merchants/${authUser?.merchant?.id}/campaigns`);
};

export const GET_MERCHANT_CAMPAIGN_BY_ID = async (
  id: string
): Promise<CampaignResponse | void> => {
  const authUser: User = getItemFromStore('authUser');
  if (authUser && authUser.merchant) {
    return fieldTrackerAPI.get(
      `/merchants/${authUser?.merchant?.id}/campaigns/${id}`
    );
  }
};

export const CREATE_MERCHANT_CAMPAIGN = async (
  campaignsPayload: CampaignRequest
): Promise<CampaignResponse> => {
  const authUser = isMerchant() as User;
  return fieldTrackerAPI.post(
    `/merchants/${authUser.merchant?.id}/campaigns`,
    campaignsPayload
  );
};

export const ASSIGN_AGENT_LOCATION = async (
  payload: AssignAgentLocationDto
): Promise<AxiosResponse<CampaignAgentLocation>> => {
  const { campaignId, ...req } = payload;
  return fieldTrackerAPI.post(`campaigns/${campaignId}/agent/set-location`, {
    ...req
  });
};

export const UPDATE_ASSIGN_AGENT_LOCATION = async (
  payload: UpdateAssignAgentLocationDto
): Promise<AxiosResponse<CampaignAgentLocation>> => {
  const { campaignId, ...req } = payload;
  return fieldTrackerAPI.patch(`campaigns/${campaignId}/agent/set-location`, {
    ...req
  });
};
