import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Col, Placeholder, Row } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import ClientCard from 'components/cards/survey-app-card/ClientCard';
import ClientTopSection from 'components/modules/crm/CardTopSection';
import { Client } from 'data/crm/survey-app-types/Client';
import { GET_CUSTOMERS_QUERY } from 'services/api/customer';
import { useQuery } from 'react-query';

const Clients = () => {
  const columns: ColumnDef<Client>[] = [
    {
      id: 'name',
      accessorFn: ({ name }) => name
    },
    {
      id: 'email',
      accessorFn: ({ email }) => email
    },
    {
      id: 'location',
      accessorFn: ({ address }) => address
    },
    {
      id: 'description',
      accessorFn: ({ description }) => description
    }
  ];

  const {
    data: clients,
    isLoading,
    isError
  } = useQuery('getCustomers', GET_CUSTOMERS_QUERY, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const table = useAdvanceTable<Client>({
    data: clients?.data || [],
    columns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  if (isLoading) {
    return (
      <div className="g-3 mt-8 mb-8">
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
      </div>
    );
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mb-9">
          <h2 className="mb-4">Clients</h2>
          <ClientTopSection />
          <Row className="g-3">
            {table
              .getRowModel()
              .rows.map(row => row.original)
              .map(client => (
                <Col xl={6} key={client.id}>
                  <ClientCard clients={client} />
                </Col>
              ))}
          </Row>
          <AdvanceTableFooter pagination className="mt-2 pb-0" />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default Clients;
