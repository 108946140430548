import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { setToken } from 'services/api/base';
import { CREATE_MERCHANT } from 'services/api/merchant';

const CreateMerchant = () => {
  const [validated, setValidated] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const queries = useQueryClient();
  const createMerchantMutation = useMutation(
    'createMerchant',
    CREATE_MERCHANT,
    {
      onSuccess: data => {
        setIsSuccessful(true);
        console.log('Merchant creation successful:', data);
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        setToken(token);
        formRef.current?.reset();
        setValidated(false);
        queries.invalidateQueries('getMerchantDetails');
        queries.invalidateQueries('getMerchants');
      },
      onError: (error: any) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('An error occurred');
        }
        console.error('Merchant creation error:', error);
      }
    }
  );

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: any;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    const formData = {
      name: form.elements.exampleMerchantName.value,
      email: form.elements.email.value,
      contactNumber: form.elements.phone.value,
      location: form.elements.location.value,
      website: form.elements.website.value,
      description: form.elements.description.value,
      password: 'password',
      roles: ['merchant']
    };

    await createMerchantMutation.mutate(formData);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="exampleMerchantName">
          <Form.Label>Merchant name</Form.Label>
          <Form.Control required type="text" placeholder="FMC Organization" />
          <Form.Control.Feedback type="invalid">
            Merchant name is required
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="merchant@gmail.com"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control type="tel" placeholder="+234 8089653445" required />
          <Form.Control.Feedback type="invalid">
            Please enter your phone number.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="location">
          <Form.Label>Location</Form.Label>
          <Form.Control type="text" placeholder="Lagos, Nigeria" required />
          <Form.Control.Feedback type="invalid">
            Please enter location.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="website">
          <Form.Label>Website</Form.Label>
          <Form.Control type="text" placeholder="www.merchantsite.com" />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            style={{ height: '100px' }}
            type="text"
            placeholder="Description"
          />
        </Form.Group>
      </Row>
      <Button type="submit" disabled={createMerchantMutation.isLoading}>
        {createMerchantMutation.isLoading ? 'Creating...' : 'Create Merchant'}
      </Button>
      {isSuccessful && (
        <p className="text-success">Merchant created successfully!</p>
      )}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </Form>
  );
};

export default CreateMerchant;
