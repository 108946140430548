import React, { useState, useEffect, useRef } from 'react';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/icons/facid.png';
import { Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { getItemFromStore } from 'helpers/utils';
import { User } from 'services/api/interface/response/User';
import { useNavigate } from 'react-router-dom';
import * as faceapi from 'face-api.js';

const LockScreenForm: React.FC = () => {
  const user: User = getItemFromStore('authUser');
  const [isBiometricAvailable, setIsBiometricAvailable] =
    useState<boolean>(false);
  const [authStatus, setAuthStatus] = useState<string>('Not started');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isFaceApiLoaded, setIsFaceApiLoaded] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const navigate = useNavigate();

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    checkBiometricAvailability();
    loadFaceApi();
    return () => {
      stopCamera();
    };
  }, []);

  const checkBiometricAvailability = (): void => {
    if (
      window.PublicKeyCredential &&
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable
    ) {
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
        .then((available: boolean) => {
          setIsBiometricAvailable(available);
        })
        .catch((error: Error) => {
          console.error('Error checking biometric availability:', error);
          setIsBiometricAvailable(false);
        });
    } else {
      setIsBiometricAvailable(false);
    }
  };

  const loadFaceApi = async () => {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
    await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
    await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
    setIsFaceApiLoaded(true);
  };

  const handleBiometricAuth = async (): Promise<void> => {
    setAuthStatus('Starting authentication...');
    setErrorMessage(null);
    if (!isBiometricAvailable) {
      console.error('Biometric authentication is not available');
      return;
    }

    const challenge: Uint8Array = new Uint8Array(32);
    window.crypto.getRandomValues(challenge);

    const publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions =
      {
        challenge: challenge,
        rp: {
          name: 'CerSaPro',
          id: location.hostname
        },
        user: {
          id: new Uint8Array(16),
          name: user.email,
          displayName: user.agent?.name || ''
        },
        pubKeyCredParams: [{ alg: -7, type: 'public-key' }],
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required'
        },
        timeout: 60000
      };

    try {
      const credential: Credential | null = await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions
      });
      console.log('Biometric authentication successful', credential);
      alert('Biometric authentication successful');
      navigate('/agent');
      setAuthStatus('Authentication successful');
      // Here you would typically send the credential to your server for verification
    } catch (error) {
      console.error('Biometric authentication failed:', error);
      setAuthStatus('Authentication failed');
      setErrorMessage(error instanceof Error ? error.message : String(error));
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const startCamera = async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: true
      });
      setStream(newStream);
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
        await videoRef.current.play();
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const handleFaceRecognitionAuth = async () => {
    setAuthStatus('Starting face recognition...');
    setErrorMessage(null);

    if (!isFaceApiLoaded) {
      setErrorMessage('Face API is not loaded yet');
      return;
    }

    try {
      await startCamera();
      const video = videoRef.current;
      if (!video) return;

      const detection = await faceapi
        .detectSingleFace(video, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceDescriptor();

      if (!detection) {
        setAuthStatus('Face not recognized');
        setErrorMessage('No face detected or face not recognized');
        return;
      }

      console.log('Face recognition successful', detection);
      alert('Face recognition successful');
      stopCamera();
      navigate('/agent');
      setAuthStatus('Authentication successful');
      // Here you would typically send the face descriptor to your server for verification
    } catch (error) {
      console.error('Face recognition failed:', error);
      setAuthStatus('Face recognition failed');
      setErrorMessage(error instanceof Error ? error.message : String(error));
    }
  };
  return (
    <div>
      <div className="text-center mb-5">
        <Avatar size="5xl" src={avatar} className="mb-3 d-inline-block" />
        <h2 className="text-1000">
          <span className="fw-normal">Hello </span>
          {user.agent?.name}
        </h2>
        <p className="text-700">
          {isBiometricAvailable
            ? 'Please use biometric authentication to proceed'
            : 'Biometric authentication is not available on this device'}
        </p>
      </div>
      <Form>
        <Form.Control
          className="mb-3"
          id="password"
          type="email"
          placeholder="Enter Password"
          disabled={true}
          defaultValue={user.email}
        />
        <Button
          variant="primary"
          size="lg"
          className="mt-3 w-10"
          onClick={handleBiometricAuth}
          disabled={!isBiometricAvailable}
        >
          {isBiometricAvailable ? 'Authenticate with Biometrics' : 'Unlock'}
        </Button>
        {!isBiometricAvailable && (
          <Button
            variant="secondary"
            size="lg"
            className="mt-3 w-10"
            onClick={handleFaceRecognitionAuth}
            disabled={!isFaceApiLoaded}
          >
            Authenticate with Face Recognition
          </Button>
        )}
      </Form>
      <p>Status: {authStatus}</p>
      {errorMessage && <p>Error: {errorMessage}</p>}
      <video ref={videoRef} style={{ display: 'none' }} />
    </div>
  );
};

export default LockScreenForm;
