import { Navbar, Nav } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import NavbarTopNav from './NavbarTopNav';
import NavbarBrandT from '../nav-items/NavbarBrandT';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import NavItems from '../nav-items/NavItems';
import { useBreakpoints } from 'providers/BreakpointsProvider';

const NavbarTopHorizontal = () => {
  const {
    config: { navbarTopShape, navbarTopAppearance }
  } = useAppContext();
  const { breakpoints } = useBreakpoints();

  return (
    <Navbar
      className={classNames('navbar-top fixed-top', {
        'navbar-slim': navbarTopShape === 'slim',
        'navbar-darker': navbarTopAppearance === 'darker'
      })}
      expand="lg"
      variant=""
    >
      <NavbarBrandT />
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav
          className="ml-auto d-flex flex-row justify-content-end"
          style={{ width: '100%' }}
        >
          <NavbarTopNav />
          <>
            {breakpoints.up('lg') && (
              <DropdownSearchBox
                className="navbar-top-search-box"
                inputClassName="rounded-pill"
                size="sm"
                style={{ width: '25rem' }}
              >
                <SearchResult />
              </DropdownSearchBox>
            )}
            <NavItems />
          </>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarTopHorizontal;
