import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { setToken } from 'services/api/base';
import { Merchant } from 'services/api/interface/response/Merchant';
import { CREATE_MERCHANT_AGENT } from 'services/api/merchant/agent';

const CreateAgent = () => {
  const [validated, setValidated] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const queries = useQueryClient();

  const createAgentMutation = useMutation(
    'createAgent',
    CREATE_MERCHANT_AGENT,
    {
      onSuccess: () => {
        setIsSuccessful(true);
        queries.invalidateQueries('getAgents');
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        setToken(token);
        setName('');
        setEmail('');
        setPhone('');
        setValidated(false);
      },
      onError: (error: any) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('An error occurred');
        }
        console.error('Agent creation error:', error);
      }
    }
  );

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: any;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    const authUser = JSON.parse(localStorage.getItem('authUser') || '{}');
    const { merchant }: { merchant: Merchant } = authUser;

    const formData = {
      name,
      email,
      phone,
      password: 'password',
      roles: ['agent'],
      merchant: {
        id: merchant?.id
      }
    };
    setValidated(true);
    try {
      await createAgentMutation.mutate(formData);
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="name">
          <Form.Label>Agent name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="John Doe"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Agent name is required
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="agent@gmail.com"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="tel"
            placeholder="+234 8089653445"
            required
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your phone number.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button type="submit" disabled={createAgentMutation.isLoading}>
        {createAgentMutation.isLoading ? 'Saving...' : 'Save'}
      </Button>
      {isSuccessful && (
        <p className="text-success">Agents created successfully!</p>
      )}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </Form>
  );
};

export default CreateAgent;
