import { AxiosResponse } from 'axios';
import { fieldTrackerAPI } from '../base';
import { isAgent, isMerchant } from '../helper';
import { CampaignResult } from '../interface/response/CampaignResult';
import { User } from '../interface/response/User';
import { Agent } from '../interface/response/Agent';
import { AgentRequest } from '../interface/request/AgentRequest';
import { getItemFromStore } from 'helpers/utils';

export const GET_ALL_MERCHANT_AGENTS = async (): Promise<
  AxiosResponse<Agent[] | [] | undefined>
> => {
  const authUser = isMerchant() as User;
  return fieldTrackerAPI.get(`/merchants/${authUser?.merchant?.id}/agents`);
};

export const GET_MERCHANT_AGENT_BY_ID = async (
  id: string
): Promise<Agent | void> => {
  const authUser: User = getItemFromStore('authUser');
  if (authUser && authUser.merchant) {
    return fieldTrackerAPI.get(
      `/merchants/${authUser?.merchant?.id}/agents/${id}`
    );
  }
};

export const CREATE_MERCHANT_AGENT = async (
  agentPayload: AgentRequest
): Promise<Agent> => {
  return fieldTrackerAPI.post(`/agents`, agentPayload);
};

export const DELETE_AGENT = async (id: string): Promise<any> => {
  return fieldTrackerAPI.delete(`/agents/${id}`);
};

export const GET_AGENT_CAMPAIGN_RESULT_BY_ID = async (): Promise<
  AxiosResponse<CampaignResult[]>
> => {
  const authUser = await isAgent();
  return fieldTrackerAPI.get(`/agents/${authUser?.agent?.id}/campaignResults/`);
};
