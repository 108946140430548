import { Button, Card, Dropdown } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import classNames from 'classnames';
import { unSetStoreItem } from 'helpers/utils';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile border border-300'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0 border-top">
          <div className="px-3">
            <Button
              onClick={() => {
                unSetStoreItem('authUser');
                unSetStoreItem('token');
                unSetStoreItem('userRole');
                unSetStoreItem('isAuthenticated');
                window.location.href = '/';
              }}
              className="btn  d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
