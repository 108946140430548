import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
  SortingState,
  PaginationState
} from '@tanstack/react-table';
import { Form, InputGroup, Button, Modal } from 'react-bootstrap';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import * as XLSX from 'xlsx';
import { CampaignResult } from 'services/api/interface/response/CampaignResult';
import { Agent } from 'services/api/interface/response/Agent';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

interface PlaceNames {
  [key: string]: string;
}

interface GraphData {
  header: string;
  data: Array<{ name: string; value: number }>;
  type: 'line' | 'bar';
}

interface AdvancedCampaignResultsTableProps {
  campaignResults: CampaignResult[];
  campaignAgents: Agent[];
  placeNames: PlaceNames;
}

const AdvancedCampaignResultsTable: React.FC<
  AdvancedCampaignResultsTableProps
> = ({ campaignResults, campaignAgents, placeNames }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const [showGraph, setShowGraph] = useState(false);
  const [graphData, setGraphData] = useState<GraphData>({
    header: '',
    data: [],
    type: 'line'
  });
  const columns = useMemo<ColumnDef<CampaignResult>[]>(() => {
    if (campaignResults.length === 0) return [];
    const firstResult = campaignResults[campaignResults.length - 1].formData;
    return [
      {
        header: 'S/N',
        accessorFn: (_, index) => index + 1
      },
      {
        header: 'AGENT NAME',
        accessorFn: row =>
          campaignAgents.find(agent => agent.id === row.agentId)?.name
      },
      ...Object.keys(firstResult).map(key => ({
        header: key.toLocaleUpperCase(),
        accessorFn: (row: { formData: { [x: string]: { value: string } } }) => {
          const value = row.formData[key]?.value || ''; // Ensure the value is fetched correctly
          if (typeof value === 'string' && value.startsWith('https://')) {
            return (
              <Link to={value} target="_blank" rel="noopener noreferrer">
                View Content
              </Link>
            );
          }
          return value.toString(); // Ensure that non-HTML strings are returned as text
        },
        cell: (info: any) => {
          const value = info.getValue();
          return typeof value === 'string' &&
            value.startsWith('<') &&
            value.endsWith('>') ? (
            <span dangerouslySetInnerHTML={{ __html: value }} />
          ) : (
            value
          );
        }
      })),
      {
        header: 'CREATED AT',
        accessorFn: row => new Date(row.createdAt).toLocaleString()
      },
      {
        header: 'LOCATION STAMP',
        accessorFn: row => placeNames[row.id] || row.locationStamp
      }
    ];
  }, [campaignResults, campaignAgents, placeNames]);

  const table = useReactTable({
    data: campaignResults,
    columns,
    state: {
      sorting,
      globalFilter,
      pagination
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });
  const isNumeric = (value: any): value is number => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };
  const handleShowGraph = (header: string) => {
    const data: { [key: string]: number } = {};
    let allNumeric = true;

    table.getFilteredRowModel().rows.forEach((row, index) => {
      const cellValue = row.getValue(header) as string | number;
      if (isNumeric(cellValue)) {
        data[`Entry ${index + 1}`] = cellValue;
      } else {
        allNumeric = false;
        data[cellValue.toString()] = (data[cellValue.toString()] || 0) + 1;
      }
    });

    const chartData = Object.entries(data).map(([name, value]) => ({
      name,
      value
    }));

    setGraphData({
      header,
      data: chartData,
      type: allNumeric ? 'line' : 'bar'
    });
    setShowGraph(true);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      table.getFilteredRowModel().rows.map(row => {
        const rowData: { [key: string]: any } = {};
        row.getAllCells().forEach(cell => {
          rowData[cell.column.id] = cell.getValue();
        });
        return rowData;
      })
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Campaign Results');
    XLSX.writeFile(workbook, 'campaign_results.xlsx');
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <Form.Group className="flex-grow mr-4">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={globalFilter}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setGlobalFilter(e.target.value)
              }
              className="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <Button
              variant="outline-secondary"
              onClick={() => setGlobalFilter('')}
              className="border-gray-300 hover:bg-gray-100"
            >
              Clear
            </Button>
          </InputGroup>
        </Form.Group>
        <Button
          onClick={exportToExcel}
          className="font-bold py-2 px-4 rounded mt-3"
          disabled={campaignResults.length == 0}
          variant="outline-secondary"
        >
          Export to Excel
        </Button>
      </div>

      <div
        className="overflow-x-auto"
        style={{ maxHeight: '500px', overflowX: 'auto' }}
      >
        <table
          className="min-w-full divide-y divide-gray-200"
          style={{ display: 'table', width: 'max-content' }}
        >
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getIsSorted() && (
                      <span>
                        {header.column.getIsSorted() === 'asc' ? '▲' : '▼'}
                      </span>
                    )}
                    {header.column.id !== 'S/N' &&
                      header.column.id !== 'Agent Name' && (
                        <Button
                          variant="circle"
                          size="sm"
                          onClick={e => {
                            e.stopPropagation();
                            handleShowGraph(header.column.id);
                          }}
                          className="ml-2 text-indigo-600 hover:text-indigo-900"
                        >
                          📊
                        </Button>
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className="hover:bg-gray-50">
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-4 py-2 ml-3  font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out"
          >
            {'<<'}
          </Button>
          <Button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-4 py-2 ml-3  font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out"
          >
            {'<'}
          </Button>
          <Button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-4 py-2 page-link ml-3"
          >
            {'>'}
          </Button>
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            className="px-4 py-2 ml-3 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue active:bg-blue-600 transition duration-150 ease-in-out"
          >
            {'>>'}
          </Button>
        </div>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
          className="px-4 py-2 border rounded-md"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <Modal show={showGraph} onHide={() => setShowGraph(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Graph for {graphData.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResponsiveContainer width="100%" height={600}>
            {graphData.type === 'line' ? (
              <LineChart data={graphData.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            ) : (
              <BarChart
                data={graphData.data}
                barSize={25}
                barGap={5}
                barCategoryGap={15}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            )}
          </ResponsiveContainer>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdvancedCampaignResultsTable;
