import React, { useEffect, useState } from 'react';
import HumanReadableTime from 'components/utils/HumanReadableTime';
import { Placeholder, Card, Row, Col } from 'react-bootstrap';
import { useQueries, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { GET_CAMPAIGN_BY_ID } from 'services/api/campaign';
import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import MCampaignAgentTable, {
  MCampaignAgentTableColumns
} from 'components/tables/survey-app-table/campaignTable/CampaignAgentTableM';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { Customer } from 'services/api/interface/response/Customer';
import { useCampaign } from 'providers/CampaignProvider';
import { CampaignResult } from 'services/api/interface/response/CampaignResult';
import { Agent } from 'services/api/interface/response/Agent';
import AdvancedCampaignResultsTable from 'components/tables/survey-app-table/campaignTable/CampaignResultTable';
import { GET_ADDRESS_QUERY } from 'services/api/public';

const ViewCampaignRes = () => {
  const { id } = useParams<{ id?: string }>();
  const [showResults, setShowResults] = useState(false);
  const [campaignAgents, setCampaignAgents] = useState<[Agent] | []>([]);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [campaignResults, setCampaignResults] = useState<[CampaignResult] | []>(
    []
  );

  const { data: campaign, isFetching: campaignFetching } = useQuery(
    ['getCampaignDetails', id],
    () => (id ? GET_CAMPAIGN_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const { setCampaign } = useCampaign();
  useEffect(() => {
    if (campaign?.data && campaign.data.customer) {
      setCampaign(campaign.data);
      setCustomer(campaign.data.customer);
      setCampaignResults(campaign.data.campaignResults as [CampaignResult]);
      setCampaignAgents(campaign.data.agents as [Agent]);
    }
  }, [campaign]);
  const addressQueries = useQueries(
    campaignResults.map(result => {
      const [latitude, longitude] = result.locationStamp.split(',').map(Number);
      return {
        queryKey: ['address', latitude, longitude],
        queryFn: () => GET_ADDRESS_QUERY({ latitude, longitude }),
        staleTime: Infinity,
        cacheTime: 1000 * 60 * 60 * 24
      };
    })
  );
  const placeNames = React.useMemo(() => {
    const names: { [key: string]: string } = {};
    addressQueries.forEach((query, index) => {
      if (query.data) {
        names[campaignResults[index].id] = query.data;
      }
    });
    return names;
  }, [addressQueries, campaignResults]);

  const table = useAdvanceTable({
    data: campaign?.data.agents || [],
    columns: MCampaignAgentTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  return (
    <div>
      {!showResults ? (
        <div>
          {campaignFetching ? (
            <div className="g-3 mt-8 mb-8">
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px'
                }}
              >
                <h2 className="mb-5">{campaign?.data?.name} Campaign</h2>
                <Button
                  variant="secondary"
                  onClick={() => setShowResults(true)}
                >
                  View Campaign Results
                </Button>
              </div>
              <Card className="mb-5 mb-xxl-7">
                <Card.Body>
                  <Row className="gy-5">
                    <Col md={6} className="d-flex justify-content-between">
                      <div>
                        <h4>Description</h4>
                        <p className="lh-sm mb-0 text-700">
                          {campaign?.data?.description}
                        </p>
                      </div>
                      <div className="my-4 mx-3 border-start d-none d-md-block"></div>
                    </Col>
                    <Col md={6}>
                      <h4>Customer</h4>

                      <p className="lh-sm mb-0 text-700">{customer?.name}</p>
                    </Col>
                    <Col md={6}>
                      <h4>Date Created</h4>

                      <p className="lh-sm mb-0 text-700">
                        <HumanReadableTime
                          date={new Date(campaign?.data?.createdAt as string)}
                        />
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div>
                <AdvanceTableProvider {...table}>
                  {campaignFetching ? (
                    <div className="g-3 mt-8 mb-8">
                      <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                      <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </div>
                  ) : (
                    <MCampaignAgentTable />
                  )}
                </AdvanceTableProvider>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <Button
            variant="phoenix-light"
            size="sm"
            onClick={() => setShowResults(false)}
            className="mb-3"
          >
            Back to Campaign Details
          </Button>
          <AdvancedCampaignResultsTable
            campaignResults={campaignResults}
            campaignAgents={campaignAgents}
            placeNames={placeNames}
          />
        </>
      )}
    </div>
  );
};

export default ViewCampaignRes;
