import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CREATE_AGENT } from 'services/api/agent';
import { setToken } from 'services/api/base';
import { GET_ALL_MERCHANTS } from 'services/api/merchant';

interface Option {
  value: any;
  label: string;
}

const CreateAgent = () => {
  const [validated, setValidated] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [merchantData, setMerchantData] = useState('');

  const queries = useQueryClient();

  const { data: merchant } = useQuery('getAllMerchants', GET_ALL_MERCHANTS, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const createAgentMutation = useMutation('createAgent', CREATE_AGENT, {
    onSuccess: () => {
      setIsSuccessful(true);
      queries.invalidateQueries('getAgents');
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      setToken(token);
      setName('');
      setEmail('');
      setPhone('');
    },
    onError: (error: any) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred');
      }
      console.error('Agent creation error:', error);
    }
  });

  const merchantOptions: Option[] = merchant?.data
    ? merchant.data.map((merchant: { id: any; name: any }) => ({
        value: merchant.id,
        label: merchant.name
      }))
    : [];

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: any;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    /*
    Merchant should be gotten from the merchant in the system and should display using a dropdown
    */

    const formData = {
      name,
      email,
      phone,
      password: 'password',
      roles: ['agent'],
      merchant: {
        id: merchantData
      }
    };

    setValidated(false);

    try {
      await createAgentMutation.mutate(formData);
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="name">
          <Form.Label>Agent name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="John Doe"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Agent name is required
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="agent@gmail.com"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="tel"
            placeholder="+234 8089653445"
            required
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your phone number.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="phone">
          <Form.Label>Merchant</Form.Label>
          <Form.Control
            as="select"
            required
            value={merchantData}
            onChange={e => setMerchantData(e.target.value)}
          >
            <option value="">Select Merchant</option>
            {merchantOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Row>
      <Button
        variant="primary"
        type="submit"
        className="mt-6"
        disabled={createAgentMutation.isLoading}
      >
        {createAgentMutation.isLoading ? 'Creating...' : 'Create Agent'}
      </Button>
      {isSuccessful && (
        <p className="text-success">Agent created successfully!</p>
      )}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </Form>
  );
};

export default CreateAgent;
