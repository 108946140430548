import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { merchantBreadcrumbItems } from 'data/commonData';
import { Card, Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { GET_MERCHANT_BY_ID } from 'services/api/merchant';

const ViewMerchant = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    data: merchant,
    isFetching
    // isSuccess
  } = useQuery(
    ['getMerchantDetails', id],
    () => (id ? GET_MERCHANT_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  return (
    <div>
      <PageBreadcrumb items={merchantBreadcrumbItems} />
      {isFetching ? (
        <h1>Loading....</h1>
      ) : (
        <div className="mb-9">
          <Row className="d-flex align-items-center justify-content-between g-3 mb-4">
            <Col xs="auto">
              <h2 className="mb-0 flex-1">{merchant?.data.name}</h2>
            </Col>
            <Col xs="auto" className="d-flex gap-3 flex-wrap">
              <Button
                variant="phoenix-danger"
                startIcon={
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                }
              >
                Delete merchant
              </Button>
            </Col>
          </Row>
          <Row className="">
            <Col xs={12} md={12}>
              <Row className="g-3 xxl-0 h-100">
                <Col xs={12} md={5} xxl={12}>
                  <Card className="h-100">
                    <Card.Body className="">
                      <div className="d-flex align-items-center mb-3">
                        <h3 className="me-1">About Merchant</h3>
                      </div>
                      <div className="mb-3">
                        <h5 className="text-800">Phone</h5>
                        <a className="text-800" href="tel:+1234567890">
                          {merchant?.data.contactNumber}
                        </a>
                      </div>
                      <div className="mb-3">
                        <h5 className="text-800">Location</h5>
                        <p className="text-800">{merchant?.data.location}</p>
                      </div>
                      <div className="mb-3">
                        <h5 className="text-800">Website</h5>
                        <p className="text-800">{merchant?.data.website}</p>
                      </div>
                      <div className="mb-3">
                        <h5 className="text-800">Description</h5>
                        <p className="text-800">{merchant?.data.description}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ViewMerchant;
