/* eslint-disable @typescript-eslint/no-explicit-any */
import { fieldTrackerAPI } from './base';

export const LOGIN = async (userPayLoad: {
  email: string;
  password: string;
  location?: string;
}): Promise<any> => {
  return fieldTrackerAPI.post('/login', userPayLoad);
};
