import { AxiosError } from 'axios';
import { getItemFromStore } from 'helpers/utils';
import { User } from './interface/response/User';

export const isMerchant = (): User | Promise<never> => {
  const authUser: User = getItemFromStore('authUser') as User;
  if (!authUser || !authUser.merchant) {
    const error: AxiosError = {
      isAxiosError: true,
      name: 'AuthorizationError',
      message: 'Merchant is not authorized to access this',
      //   config: {},
      toJSON: () => ({})
    };
    return Promise.reject(error);
  }
  return authUser;
};

export const isAgent = (): User | Promise<never> => {
  const authUser: User = getItemFromStore('authUser') as User;
  if (!authUser || !authUser.agent) {
    const error: AxiosError = {
      isAxiosError: true,
      name: 'AuthorizationError',
      message: 'Agent is not authorized to access this campaign',
      //   config: {},
      toJSON: () => ({})
    };
    return Promise.reject(error);
  }
  return authUser;
};

export const isCustomer = (): User | Promise<never> => {
  const authUser: User = getItemFromStore('authUser') as User;
  if (!authUser || !authUser.customer) {
    const error: AxiosError = {
      isAxiosError: true,
      name: 'AuthorizationError',
      message: 'Customer is not authorized to access this campaign',
      //   config: {},
      toJSON: () => ({})
    };
    return Promise.reject(error);
  }
  return authUser;
};
