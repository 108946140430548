import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import { Report } from 'data/crm/reportsData';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ClientTopSection = () => {
  const { setGlobalFilter } = useAdvanceTableContext<Report>();
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };
  return (
    <Row className="g-3 justify-content-between mb-4">
      <Col xs="auto">
        <Link to={'/admin/create-client'}>
          <Button
            variant="primary"
            className="me-4"
            startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
          >
            Create Client
          </Button>
        </Link>
      </Col>
      <Col xs="auto">
        <div className="d-flex">
          <SearchBox
            placeholder="Search by name"
            className="me-2"
            onChange={handleSearchInputChange}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ClientTopSection;
