import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import MerchantTable, {
  merchantTableColumns
} from 'components/tables/survey-app-table/MerchantTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useState } from 'react';
import { Col, Offcanvas, Placeholder, Row } from 'react-bootstrap';
import CreateMerchant from './CreateMerchant';
import { GET_ALL_MERCHANTS } from 'services/api/merchant';
import { useQuery } from 'react-query';

const Merchant = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    data: merchants,
    isLoading,
    isError
  } = useQuery('getMerchants', GET_ALL_MERCHANTS, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const table = useAdvanceTable({
    data: merchants?.data,
    columns: merchantTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <div className="mb-6">
        {isError && <>Error</>}
        <AdvanceTableProvider {...table}>
          <h2 className="mb-4">{merchants?.length} Merchants</h2>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <Button
                variant="primary"
                className="me-4"
                startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
                onClick={handleShow}
              >
                Create merchant
              </Button>
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Search by name"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            {isLoading ? (
              <div className="g-3 mt-8 mb-8">
                <Placeholder as="p" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
                <Placeholder as="p" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
            ) : (
              <MerchantTable />
            )}
          </div>
        </AdvanceTableProvider>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Merchant</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateMerchant />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Merchant;
