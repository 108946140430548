import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import App from 'App';

// Survey app routes

// Admin Routes
import LoginPage from 'pages/apps/survey/auth/Login/SignIn';
import Merchant from 'pages/apps/survey/app/Admin/Merchant/Merchant';
import ViewMerchant from 'pages/apps/survey/app/Admin/Merchant/ViewMerchant';
import Clients from 'pages/apps/survey/app/Admin/Clients/Clients';
import CreateClient from 'pages/apps/survey/app/Admin/Clients/CreateClient';
import ViewClient from 'pages/apps/survey/app/Admin/Clients/View';
import Agents from 'pages/apps/survey/app/Admin/Agents/Agents';
import Campaign from 'pages/apps/survey/app/Admin/Campaign/Campaign';
import AdminCreateCampaign from 'pages/apps/survey/app/Admin/Campaign/CreateCampaign';
import AdminViewCampaignRes from 'pages/apps/survey/app/Admin/Campaign/AdminViewCampaignRes';
import AddAgentAdmin from 'pages/apps/survey/app/Admin/Campaign/AddAgentAdmin';

// Merchant routes
import CreateCampaign from 'pages/apps/survey/app/Merchant/Campaign/CreateCampaign';
import MerchantCampaign from 'pages/apps/survey/app/Merchant/Campaign/Campaign';
import MerchantAgents from 'pages/apps/survey/app/Merchant/Agents/Agents';
import AddAgent from 'pages/apps/survey/app/Merchant/Campaign/AddAgent';
import MerchantClients from 'pages/apps/survey/app/Merchant/MerchantClients/MerchantClients';
import MerchantCreateClient from 'pages/apps/survey/app/Merchant/MerchantClients/CreateMerchantClient';
import MerchantViewClient from 'pages/apps/survey/app/Merchant/MerchantClients/MerchantView';

// Agents Routes
import AgentLayout from 'layouts/AgentLayout';
import AgentCampaign from 'pages/apps/survey/app/Agents/Campaign/AgentCampaign';
import AgentResults from 'pages/apps/survey/app/Agents/Results/AgentResults';
import ViewCampaignForm from 'pages/apps/survey/app/Agents/Campaign/ViewCampaignForm';
import ViewCampaignResult from 'pages/apps/survey/app/Agents/Results/ViewCampaignResult';
import CustomerResults from 'pages/apps/survey/app/Clients/Results/CustomerResults';
import ViewCampaignResultCustomer from 'pages/apps/survey/app/Clients/Results/ViewCampaignResultCustomer';
import ViewCampaignRes from 'pages/apps/survey/app/Merchant/Campaign/ViewCampaignRes';
import AgentBioLockScreen from 'pages/apps/survey/auth/Login/AgentBioLockScreen';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <LoginPage />
      },
      {
        path: 'bio-auth',
        element: <AgentBioLockScreen />
      },
      {
        path: '/admin',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'merchant',
            element: <Merchant />
          },
          {
            path: 'view-merchant/:id',
            element: <ViewMerchant />
          },
          {
            path: 'clients',
            element: <Clients />
          },
          {
            path: 'create-client',
            element: <CreateClient />
          },
          {
            path: 'view-client/:id',
            element: <ViewClient />
          },
          {
            path: 'agents',
            element: <Agents />
          },
          {
            path: 'campaign',
            element: <Campaign />
          },
          {
            path: 'create-campaign',
            element: <AdminCreateCampaign />
          },
          {
            path: 'campaign/results/:id',
            element: <AdminViewCampaignRes />
          },
          {
            path: 'campaign/:id/add-agent',
            element: <AddAgentAdmin />
          }
        ]
      },
      {
        path: '/merchant',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'clients',
            element: <MerchantClients />
          },
          {
            path: 'create-client',
            element: <MerchantCreateClient />
          },
          {
            path: 'view-client/:id',
            element: <MerchantViewClient />
          },
          {
            path: 'agents',
            element: <MerchantAgents />
          },
          {
            path: 'campaign',
            element: <MerchantCampaign />
          },
          {
            path: 'campaign/results/:id',
            element: <ViewCampaignRes />
          },
          {
            path: 'create-campaign',
            element: <CreateCampaign />
          },
          {
            path: 'campaign/:id/add-agent',
            element: <AddAgent />
          }
        ]
      },
      {
        path: '/agent',
        element: (
          <MainLayoutProvider>
            <AgentLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: '',
            element: <AgentCampaign />
          },
          {
            path: 'campaign/:id',
            element: <ViewCampaignForm />
          },
          {
            path: 'results',
            element: <AgentResults />
          },
          {
            path: 'results/:id',
            element: <ViewCampaignResult />
          }
        ]
      },
      {
        path: '/customer',
        element: (
          <MainLayoutProvider>
            <AgentLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'results',
            element: <CustomerResults />
          },
          {
            path: 'results/:id',
            element: <ViewCampaignResultCustomer />
          }
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
