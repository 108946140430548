import classNames from 'classnames';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const AgentLayout = () => {
  const { contentClass } = useMainLayoutContext();

  return (
    <Container fluid className="px-0">
      <NavbarTopHorizontal />

      <div className={classNames(contentClass, 'content')}>
        <Outlet />
      </div>
    </Container>
  );
};

export default AgentLayout;
