import LockScreenForm from 'components/modules/auth/LockScreenForm';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bg from 'assets/img/sections/3047867.jpg';
import { getItemFromStore } from 'helpers/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AgentBioLockScreen = () => {
  const isAuthenticated = getItemFromStore('isAuthenticated');
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/agent');
    }
  }, [isAuthenticated]);
  return (
    <AuthSplitLayout bg={bg} logo={false}>
      <LockScreenForm />
    </AuthSplitLayout>
  );
};

export default AgentBioLockScreen;
