import { AxiosResponse } from 'axios';
import { fieldTrackerAPI } from '../base';
import { User } from '../interface/response/User';
import { Customer } from '../interface/response/Customer';
import { isMerchant } from '../helper';
import { getItemFromStore } from 'helpers/utils';
import { CustomerRequest } from '../interface/request/CustomerRequest';

export const GET_ALL_MERCHANT_CUSTOMERS = async (): Promise<
  AxiosResponse<Customer[] | [] | undefined>
> => {
  const authUser = isMerchant() as User;
  return fieldTrackerAPI.get(`/merchants/${authUser?.merchant?.id}/customers`);
};

export const GET_MERCHANT_CUSTOMER_BY_ID = async (
  id: string
): Promise<Customer | void> => {
  const authUser: User = getItemFromStore('authUser');
  if (authUser && authUser.merchant) {
    return fieldTrackerAPI.get(
      `/merchants/${authUser?.merchant?.id}/customers/${id}`
    );
  }
};

export const CREATE_CUSTOMER = async (customerPayload: {
  password: string;
  roles: string[];
  email: string;
  address: string;
  name: string;
  description?: string;
  merchant?: { id: string };
}): Promise<any> => {
  return fieldTrackerAPI.post('/customers', customerPayload);
};

export const CREATE_MERCHANT_CUSTOMER = async (
  customerPayload: CustomerRequest
): Promise<Customer> => {
  return fieldTrackerAPI.post(`/customers`, customerPayload);
};

export const DELETE_CUSTOMER = async (id: string): Promise<any> => {
  return fieldTrackerAPI.delete(`/customers/${id}`);
};
