import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AccessTokenProvider } from 'providers/AccessTokenProvider';
import { CookiesProvider } from 'react-cookie';
const queryClient = new QueryClient();
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { CampaignProvider } from 'providers/CampaignProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <AccessTokenProvider>
          <AppProvider>
            <CampaignProvider>
              <SettingsPanelProvider>
                <BreakpointsProvider>
                  <RouterProvider router={router} />
                </BreakpointsProvider>
              </SettingsPanelProvider>
            </CampaignProvider>
          </AppProvider>
        </AccessTokenProvider>
      </QueryClientProvider>
    </CookiesProvider>
  </React.StrictMode>
);
serviceWorkerRegistration.register();
