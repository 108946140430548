import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import AgentTable, {
  agentTableColumns
} from 'components/tables/survey-app-table/AgentTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useState } from 'react';
import { Col, Offcanvas, Placeholder, Row } from 'react-bootstrap';
import CreateAgent from './CreateAgent';
import { GET_AGENTS_QUERY } from 'services/api/agent';
import { useQuery } from 'react-query';

const Agents = () => {
  const [show, setShow] = useState(false);

  const {
    data: agents,
    isLoading,
    isError
  } = useQuery('getAgent', GET_AGENTS_QUERY, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const table = useAdvanceTable({
    data: agents?.data || [],
    columns: agentTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <div className="mb-6">
        {isError && <>Error</>}
        <AdvanceTableProvider {...table}>
          <h2 className="mb-4">{agents?.data?.length} Agents</h2>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <Button
                variant="primary"
                className="me-4"
                startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
                onClick={handleShow}
              >
                Create Agent
              </Button>
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Search by name"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          {isLoading ? (
            <div className="g-3 mt-8 mb-8">
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </div>
          ) : (
            <AgentTable />
          )}
        </AdvanceTableProvider>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Agents</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateAgent />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Agents;
