import { useInstallPrompt } from 'hooks/useInstallPrompt';
import React from 'react';
import { Offcanvas, Button } from 'react-bootstrap';

interface InstallPromptOffcanvasProps {
  className?: string;
  showModal: boolean;
}

const InstallPromptOffcanvas: React.FC<InstallPromptOffcanvasProps> = ({
  className = '',
  showModal = false
}) => {
  const { handleInstallClick } = useInstallPrompt();

  const handleClose = () => {
    console.log('>>>');
  };

  const handleInstall = () => {
    handleInstallClick();
  };

  return (
    <Offcanvas
      show={showModal}
      onHide={handleClose}
      placement="top"
      className={className}
    >
      <Offcanvas.Header closeButton className="mt-3 mb-3">
        <Offcanvas.Title>Install the App</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="mt-3 mb-3">
        <p>
          Install the app to continue using the app and also for a better
          experience!
        </p>
        <div className="d-flex justify-content-end mt-3">
          <Button variant="secondary" onClick={handleClose} className="me-2">
            Dismiss
          </Button>
          <Button variant="primary" onClick={handleInstall}>
            Install
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default InstallPromptOffcanvas;
