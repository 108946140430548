import { useCallback, useEffect, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Button,
  Placeholder,
  FormControl,
  FormGroup,
  InputGroup,
  Image
} from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GET_CAMPAIGN_BY_ID,
  GET_CAMPAIGNFORM_BY_ID
} from 'services/api/campaign';
import {
  CREATE_CAMPAIGN_RESULT,
  UPLOAD_FILE
} from 'services/api/campaignResult';
import { CampaignForm } from 'services/api/interface/response/CampaignForm';
// import GoogleMapLocation from './components/map';
interface Location {
  lat: number;
  lng: number;
}
interface FormValue {
  name: string;
  value: string | boolean | number | File | any;
}
interface FormValues {
  [key: string]: FormValue;
}
const ViewCampaignForm = () => {
  const { id } = useParams<{ id?: string }>();
  const [location, setLocation] = useState<Location | null>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const navigate = useNavigate();
  const queries = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileProp, setFileProp] = useState<string>('');

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const loc = await getLocation();
        setLocation(loc);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    fetchLocation();
  }, []);

  const { data: campaign, isFetching: campaignFetching } = useQuery(
    ['getCampaignDetails', id],
    () => (id ? GET_CAMPAIGN_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const { data: campaignForm, isFetching: campaignFormFetching } = useQuery(
    ['getCampaignForm', id],
    () => (id ? GET_CAMPAIGNFORM_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );
  const uploadFileMutation = useMutation(UPLOAD_FILE, {
    onSuccess: data => {
      console.log('File uploaded successfully:', data);
      setFileUrl(data.url);
      setIsUploading(false);
      const name = fileProp;
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: { name, value: data.url }
      }));
    },
    onError: error => {
      console.error('Error uploading file:', error);
      setError('Failed to upload file. Please try again.');
      setIsUploading(false);
    }
  });

  const handleFileUpload = useCallback(async () => {
    if (!selectedFile) {
      setError('Please select a file first.');
      return;
    }

    setIsUploading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await uploadFileMutation.mutateAsync(formData);
    } catch (error) {
      console.error('Error in file upload:', error);
    }
  }, [selectedFile, uploadFileMutation]);
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = e.target;

    const fieldValue =
      type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

    setFormValues(prevValues => ({
      ...prevValues,
      [name]: { name, value: fieldValue }
    }));
  };
  const mutation = useMutation(CREATE_CAMPAIGN_RESULT, {
    onSuccess: data => {
      console.log('Campaign result created successfully:', data);
      setSubmitting(false);
      queries.invalidateQueries('getCampaignDetails');
      queries.invalidateQueries('getCampaignForm');
      navigate('/agent');
    },
    onError: error => {
      console.error('Error creating campaign result:', error);
    }
  });
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLocation(null);
    setSubmitting(true);
    const loc = await getLocation();
    setLocation(loc);
    const authUser = JSON.parse(localStorage.getItem('authUser') || '{}');

    const payload = {
      campaign: { id: id! },
      agent: { id: authUser.agent.id },
      locationStamp: `${location?.lat},${location?.lng}`,
      formData: formValues,
      customer: { id: campaign?.data?.customer?.id || '' },
      merchant: { id: campaign?.data?.merchant?.id || '' }
    };
    console.log(formValues, payload);
    try {
      mutation.mutateAsync(payload);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, files } = event.target;
      setFileProp(name);
      const file = files?.[0];
      setError(null);
      setFileUrl(null);
      if (file) {
        if (file.size > 5 * 1024 * 1024) {
          setError('File size exceeds 5MB limit.');
          return;
        }

        setSelectedFile(file);

        const reader = new FileReader();
        reader.onload = e => setPreviewUrl(e.target?.result as string);
        reader.onerror = () => setError('Failed to read file.');
        reader.readAsDataURL(file);
      } else {
        setSelectedFile(null);
        setPreviewUrl(null);
        setFormValues(prevValues => {
          const newValues = { ...prevValues };
          delete newValues.file;
          return newValues;
        });
      }
    },
    []
  );
  const renderField = (field: CampaignForm) => {
    switch (field.fieldType) {
      case 'text':
      case 'number':
      case 'date':
      case 'time':
        return (
          <Form.Control
            required={field.isRequired}
            type={field.fieldType}
            name={field.label}
            placeholder={`Enter ${field.label}`}
            onChange={handleChange}
          />
        );
      case 'textarea':
        return (
          <Form.Control
            as="textarea"
            required={field.isRequired}
            name={field.label}
            placeholder={`Enter ${field.label}`}
            onChange={handleChange}
          />
        );
      case 'checkbox':
        return (
          <Form.Check
            required={field.isRequired}
            type="checkbox"
            name={field.label}
            label={`Check ${field.label}`}
            onChange={handleChange}
          />
        );
      case 'dropdown':
      case 'multiple':
        return (
          <Form.Control
            as="select"
            required={field.isRequired}
            name={field.label}
            onChange={handleChange}
          >
            <option value="">Select {field.label}</option>
            {field.options?.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </Form.Control>
        );
      case 'file':
        return (
          <FormGroup>
            <InputGroup className="mb-3">
              <FormControl
                type="file"
                accept=".png,.jpg,.jpeg,.pdf"
                onChange={handleFileChange}
                hidden
                id={`fileInput-${field.label}`}
                name={field.label}
                required={field.isRequired}
              />
              <InputGroup.Text id={`inputGroupFileAddon-${field.label}`}>
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    document.getElementById(`fileInput-${field.label}`)?.click()
                  }
                >
                  Choose File
                </Button>
              </InputGroup.Text>
              <FormControl
                type="text"
                placeholder="No file chosen"
                value={selectedFile?.name || ''}
                readOnly
                aria-describedby={`inputGroupFileAddon-${field.label}`}
              />
              <Button
                variant="outline-secondary"
                onClick={handleFileUpload}
                disabled={!selectedFile || isUploading}
              >
                {isUploading ? 'Uploading...' : 'Upload File'}
              </Button>
            </InputGroup>
            {error && <div className="text-danger">{error}</div>}
            {fileUrl && (
              <div className="text-success">File uploaded successfully</div>
            )}
            {previewUrl && (
              <Image
                src={previewUrl}
                alt="File preview"
                fluid
                style={{ maxWidth: '300px', marginTop: '10px' }}
              />
            )}
          </FormGroup>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <div>
        {campaignFetching || campaignFormFetching ? (
          <div className="g-3 mt-8 mb-8">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        ) : (
          <div>
            <h2 className="mb-5">{campaign?.data?.name} Campaign</h2>
            <div>
              <Form onSubmit={handleSubmit}>
                {campaignForm?.data.map((field, index) => (
                  <Row className="mb-3" key={index}>
                    <Form.Group as={Col} md="8" controlId={`field-${index}`}>
                      <Form.Label>
                        {field.label}
                        {field.isRequired && (
                          <span className="text-danger">*</span>
                        )}
                      </Form.Label>
                      {renderField(field)}
                      <Form.Control.Feedback type="invalid">
                        {field.label} is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                ))}
                <Button type="submit" disabled={submitting}>
                  Submit
                </Button>
              </Form>
            </div>
            {/* <GoogleMapLocation locCords={location as Location} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewCampaignForm;

const getLocation = (): Promise<Location> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos: Location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          resolve(pos);
        },
        error => reject(error.message),
        {
          enableHighAccuracy: true
        }
      );
    } else {
      reject('Geolocation is not supported by this browser.');
    }
  });
};
