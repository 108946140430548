import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface AddAgentModalProps {
  show: boolean;
  handleClose: () => void;
  latestCampaign?: any;
}

const AddAgentModal: React.FC<AddAgentModalProps> = ({
  show,
  handleClose,
  latestCampaign
}) => {
  const navigate = useNavigate();

  const handleAddAgents = () => {
    // Assuming latestCampaign has an id property
    if (latestCampaign) {
      navigate(`/admin/campaign/${latestCampaign.id}/add-agent`);
    }
  };
  return (
    <Modal show={show} onHide={handleClose} className="p-0" centered>
      <Modal.Header className="border-200 p-4">
        <h5 className="modal-title text-1000 fs-6 lh-sm">Add Agents</h5>
        <Button className="p-1" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} className="fs-9" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-2 px-4 mb-5">
        <h4>Campaign has been created successfully</h4>
        <span>Click on the button to add agent to the campaign</span>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center px-4 pb-4 border-0 pt-3">
        <Button
          variant="primary"
          size="sm"
          className="px-9 fs-9 my-0"
          type="submit"
          onClick={handleAddAgents}
        >
          Add Agents
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAgentModal;
