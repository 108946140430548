import axios from 'axios';

export const fieldTrackerAPI = axios.create({
  baseURL:
    process.env.NODE_ENV && process.env.NODE_ENV === 'production'
      ? 'https://field-tracker-backend-kvsj2ozssa-uc.a.run.app/api'
      : 'https://field-tracker-backend-kvsj2ozssa-uc.a.run.app/api'
});

export const setToken = (token: string | null) => {
  fieldTrackerAPI.defaults.headers.common['Authorization'] = token
    ? `Bearer ${token}`
    : '';
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
};

export type Query<T> = {
  queryKey: (string | T)[];
};

export interface PaginatedResponse<T> {
  items: T[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

fieldTrackerAPI.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Request interceptor for adding Bearer token to requests
fieldTrackerAPI.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 401:
          // Handle unauthorized access (e.g., redirect to login)
          localStorage.removeItem('token');
          localStorage.removeItem('authUser');
          console.error('Unauthorized access');
          if (location.pathname !== '/') {
            location.pathname = '/';
          }

          break;
        case 403:
          // console.error('Forbidden access', error);
          // location.pathname = '/pages/errors/403';
          break;
        case 400:
          // Handle bad request (e.g., display validation errors)
          console.error('Bad request');
          break;
        case 404:
          // Handle bad request (e.g., display validation errors)
          console.error('Bad request');
          // location.pathname = '/pages/errors/404';
          break;
        default:
          // Handle other errors
          // location.pathname = '/pages/errors/500';
          console.error('An error occurred:', error.response.data);
      }
    } else if (error.request) {
      // Handle request errors (e.g., network errors)
      console.error('Network error:', error.request);
    } else {
      // Handle other errors
      console.error('An error occurred:', error.message);
    }
    return Promise.reject(error);
  }
);
