import { AxiosResponse } from 'axios';
import { fieldTrackerAPI } from './base';
import { isAgent } from './helper';
import { CampaignResult } from './interface/response/CampaignResult';
import { Agent } from './interface/response/Agent';

export const GET_AGENTS_QUERY = async (): Promise<
  AxiosResponse<Agent[] | [] | undefined>
> => {
  return fieldTrackerAPI.get('/agents');
};

export const GET_AGENT_BY_ID = async (id: string): Promise<any> => {
  return fieldTrackerAPI.get(`/agents/${id}`);
};

export const CREATE_AGENT = async (agentPayload: {
  password: string;
  roles: string[];
  email: string;
  phone: string;
  name: string;
  merchant: { id: string };
}): Promise<any> => {
  return fieldTrackerAPI.post('/agents', agentPayload);
};

export const DELETE_AGENT = async (id: string): Promise<any> => {
  return fieldTrackerAPI.delete(`/agents/${id}`);
};

export const GET_AGENT_CAMPAIGN_RESULT_BY_ID = async (): Promise<
  AxiosResponse<[CampaignResult] | []>
> => {
  const authUser = await isAgent();
  return fieldTrackerAPI.get(`/agents/${authUser?.agent?.id}/campaignResults/`);
};
