import { AxiosResponse } from 'axios';
import { fieldTrackerAPI } from './base';
import { CampaignRequest } from './interface/request/CampaignRequest';
import { CampaignResponse } from './interface/response/Campaign';
import { CampaignForm } from './interface/response/CampaignForm';

export const GET_ALL_CAMPAIGN = async (): Promise<any> => {
  return fieldTrackerAPI.get('/campaigns');
};

export const GET_CAMPAIGN_BY_ID = async (
  id: string
): Promise<AxiosResponse<CampaignResponse>> => {
  return fieldTrackerAPI.get(`/campaigns/${id}`);
};

export const CREATE_CAMPAIGN = async (
  campaignsPayload: CampaignRequest
): Promise<any> => fieldTrackerAPI.post('/campaigns', campaignsPayload);

export const DELETE_CAMPAIGN = async (id: string): Promise<any> => {
  return fieldTrackerAPI.delete(`/campaigns/${id}`);
};

export const ADD_CAMPAIGN_AGENTS = async (
  id: string,
  agents: { id: string }[]
): Promise<any> => {
  return fieldTrackerAPI.post(`/campaigns/${id}/agents`, agents);
};

export const GET_CAMPAIGNS_BY_AGENT_ID = async (
  agentId: string
): Promise<any> => {
  return fieldTrackerAPI.get(`/agents/${agentId}/campaigns`);
};

export const GET_CAMPAIGNS_BY_CLIENT_ID = async (
  clientId: string
): Promise<any> => {
  return fieldTrackerAPI.get(`/customers/${clientId}/campaigns`);
};

export const GET_CAMPAIGNFORM_BY_ID = async (
  Id: string
): Promise<AxiosResponse<CampaignForm[] | []>> => {
  return fieldTrackerAPI.get(`/campaigns/${Id}/campaignForms`);
};

export const GET_CAMPAIGN_RESULT_BY_ID = async (Id: string): Promise<any> => {
  return fieldTrackerAPI.get(`/campaigns/${Id}/campaignResults`);
};

export const GET_CAMPAIGN_AGENT = async (Id: string): Promise<any> => {
  return fieldTrackerAPI.get(`/campaigns/${Id}/agents`);
};
