import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { agentDataType } from 'data/crm/survey-app-types/agent';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

export const clientResultTableColumns: ColumnDef<agentDataType>[] = [
  {
    accessorKey: 'customer.name',
    header: 'Campaign Name',
    cell: ({ row: { original } }) => {
      const { name, id } = original;
      return (
        <div className="d-flex align-items-center">
          <Link to={`/customer/results/${id}`}>
            <div>{name}</div>
          </Link>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    id: 'leadDropdown',
    cell: ({ row: { original } }) => {
      const { id } = original;

      const navigate = useNavigate();
      const handleview = () => {
        return navigate(`/customer/results/${id}`);
      };

      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item eventKey="1" onClick={handleview}>
              View
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  }
];

const ClientResultTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default ClientResultTable;
