/* eslint-disable @typescript-eslint/no-explicit-any */
import { fieldTrackerAPI } from './base';

export const CREATE_CAMPAIGN_RESULT = async (payload: {
  campaign: { id: string };
  agent: { id: string };
  locationStamp: string;
  formData: object;
  customer: { id: string };
  merchant: { id: string };
}) => {
  return fieldTrackerAPI.post('/campaignResults', payload);
};

export const UPLOAD_FILE = async (formData: FormData) => {
  const response = await fieldTrackerAPI.post(
    '/campaignResults/uploadFile',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return response.data; // Assuming this returns { url: 'uploaded-file-url' }
};
