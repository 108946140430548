import React, { useEffect, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import { useQueries, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { GET_CAMPAIGN_BY_ID } from 'services/api/campaign';
import { useCampaign } from 'providers/CampaignProvider';
import { CampaignResult } from 'services/api/interface/response/CampaignResult';
import { GET_ADDRESS_QUERY } from 'services/api/public';
import AdvancedCampaignResultsTable from 'components/tables/survey-app-table/campaignTable/CampaignResultTable';
import { Agent } from 'services/api/interface/response/Agent';

const ViewCampaignResultCustomer = () => {
  const { id } = useParams<{ id?: string }>();
  const [campaignAgents, setCampaignAgents] = useState<[Agent] | []>([]);
  const [campaignResults, setCampaignResults] = useState<[CampaignResult] | []>(
    []
  );

  const { data: campaign, isFetching: campaignFetching } = useQuery(
    ['getCampaignDetails', id],
    () => (id ? GET_CAMPAIGN_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const { setCampaign } = useCampaign();
  useEffect(() => {
    if (campaign?.data && campaign.data.customer) {
      setCampaign(campaign.data);
      setCampaignResults(campaign.data.campaignResults as [CampaignResult]);
      setCampaignAgents(campaign.data.agents as [Agent]);
    }
  }, [campaign]);
  const addressQueries = useQueries(
    campaignResults.map(result => {
      const [latitude, longitude] = result.locationStamp.split(',').map(Number);
      return {
        queryKey: ['address', latitude, longitude],
        queryFn: () => GET_ADDRESS_QUERY({ latitude, longitude }),
        staleTime: Infinity,
        cacheTime: 1000 * 60 * 60 * 24
      };
    })
  );
  const placeNames = React.useMemo(() => {
    const names: { [key: string]: string } = {};
    addressQueries.forEach((query, index) => {
      if (query.data) {
        names[campaignResults[index].id] = query.data;
      }
    });
    return names;
  }, [addressQueries, campaignResults]);

  return (
    <div>
      <div>
        {campaignFetching ? (
          <div className="g-3 mt-8 mb-8">
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        ) : (
          <div>
            <h2 className="mb-5">{campaign?.data?.name} Campaign</h2>
            <div>
              <AdvancedCampaignResultsTable
                campaignResults={campaignResults}
                campaignAgents={campaignAgents}
                placeNames={placeNames}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewCampaignResultCustomer;
