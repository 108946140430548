import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import AddAgentModal from './AddAgentModal';
import { CampaignResponse } from 'services/api/interface/response/Campaign';
import { CREATE_CAMPAIGN } from 'services/api/campaign';
import { cleanText, snakeCase, unSetStoreItem } from 'helpers/utils';
import {
  CampaignRequest,
  Field
} from 'services/api/interface/request/CampaignRequest';

const CreateForm: React.FC<{ campaign?: CampaignRequest }> = ({ campaign }) => {
  const [fields, setFields] = useState<Field[]>([]);
  const [newField, setNewField] = useState<Field>({
    label: '',
    fieldType: 'text',
    options: []
  });
  const [newOption, setNewOption] = useState<string>('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [savedCampaign, setSavedCampaign] = useState<CampaignResponse | null>(
    null
  );

  const handleAddField = () => {
    if (newField.label.trim() !== '') {
      setFields([...fields, newField]);
      setNewField({ label: '', fieldType: 'text', options: [] });
    }
  };

  const handleRemoveField = (index: number) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleChangeNewFieldLabel = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewField({ ...newField, label: e.target.value });
  };

  const handleChangeNewFieldType = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewField({
      ...newField,
      fieldType: e.target.value as Field['fieldType'],
      options:
        e.target.value === 'multiple' || e.target.value === 'dropdown'
          ? []
          : undefined
    });
  };

  const handleAddOption = () => {
    if (newOption.trim() !== '') {
      setNewField({
        ...newField,
        options: [...(newField.options || []), newOption]
      });
      setNewOption('');
    }
  };

  const handleChangeNewOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewOption(e.target.value);
  };

  const queryClient = useQueryClient();
  const createCampaignMutation = useMutation(CREATE_CAMPAIGN, {
    onSuccess: (data: { data: CampaignResponse }) => {
      setIsSuccessful(true);
      setSavedCampaign(data?.data as CampaignResponse);
      queryClient.invalidateQueries('getCampaigns');
      setErrorMessage('');
      setShowModal(true);
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred');
      }
    }
  });

  const handleSubmit = async () => {
    if (campaign) {
      try {
        createCampaignMutation.mutateAsync({
          ...campaign,
          formFields: fields
        });
        unSetStoreItem('notSubmittedCampaign');
      } catch (error) {
        console.error('Error creating campaign form:', error);
      }
    } else {
      console.log('No campaign available.');
    }
  };
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <>
        <Form>
          <Form.Label style={{ fontSize: '20px', textTransform: 'capitalize' }}>
            Add Form Field
          </Form.Label>
          <Row>
            <Col md="5">
              <Form.Group>
                <Form.Label>Label</Form.Label>
                <Form.Control
                  type="text"
                  value={newField.label}
                  onChange={handleChangeNewFieldLabel}
                />
              </Form.Group>
            </Col>
            <Col md="5">
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Select
                  value={newField.fieldType}
                  onChange={handleChangeNewFieldType}
                >
                  <option value="text">Short Text Answer</option>
                  <option value="textarea">Paragraph</option>
                  <option value="number">Number Input</option>
                  <option value="file">File Upload</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="multiple">Multiple Choice</option>
                  <option value="date">Date</option>
                  <option value="dropdown">Dropdown</option>
                  <option value="time">Time</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {(newField.fieldType === 'multiple' ||
            newField.fieldType === 'dropdown') && (
            <Row>
              <Col>
                <Form.Group as={Col} md="10">
                  <Form.Label>Option</Form.Label>
                  <Form.Control
                    type="text"
                    value={newOption}
                    onChange={handleChangeNewOption}
                  />
                  <Button
                    variant="secondary"
                    onClick={handleAddOption}
                    className="mt-2"
                  >
                    Add Option
                  </Button>
                </Form.Group>
                <ul>
                  {newField.options?.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}
          <Button variant="light" onClick={handleAddField} className="my-3">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
          </Button>

          {fields.map((field, index) => (
            <div key={index}>
              <Form.Group as={Col} md="10">
                <Form.Label>{field.label}</Form.Label>
                {field.fieldType === 'text' && <Form.Control type="text" />}
                {field.fieldType === 'textarea' && (
                  <Form.Control as="textarea" />
                )}
                {field.fieldType === 'number' && <Form.Control type="number" />}
                {field.fieldType === 'file' && <Form.Control type="file" />}
                {field.fieldType === 'date' && <Form.Control type="date" />}
                {field.fieldType === 'time' && <Form.Control type="time" />}
                {field.fieldType === 'checkbox' && (
                  <Form.Check type="checkbox" label={field.label} />
                )}
                {field.fieldType === 'radio' && (
                  <Form.Check type="radio" label={field.label} />
                )}
                {field.fieldType === 'multiple' && (
                  <>
                    {field.options?.map((option, idx) => (
                      <Form.Check
                        key={idx}
                        type="radio"
                        name={snakeCase(cleanText(field.label))}
                        label={option}
                      />
                    ))}
                  </>
                )}
                {field.fieldType === 'dropdown' && (
                  <Form.Select>
                    {field.options?.map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                )}
                <Button
                  variant="light"
                  onClick={() => handleRemoveField(index)}
                  className="mt-2"
                >
                  <FontAwesomeIcon icon={faMinus} className="me-2" />
                </Button>
              </Form.Group>
              <hr />
            </div>
          ))}
        </Form>

        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
        {isSuccessful && (
          <p className="text-success">CampaignForm created successfully!</p>
        )}
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </>
      <AddAgentModal
        show={showModal}
        handleClose={handleCloseModal}
        latestCampaign={savedCampaign}
      />
    </>
  );
};

export default CreateForm;
