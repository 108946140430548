import { useState, useEffect } from 'react';

type BeforeInstallPromptEvent = Event & {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string }>;
};

export const useInstallPrompt = () => {
  const [installPrompt, setInstallPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isInstalled, setIsInstalled] = useState<boolean>(false);

  useEffect(() => {
    const checkInstallation = () => {
      const isStandalone = window.matchMedia(
        '(display-mode: standalone)'
      ).matches;
      // For iOS devices
      const isInWebAppiOS = (window.navigator as any).standalone === true;
      setIsInstalled(isStandalone || isInWebAppiOS);
    };

    checkInstallation();

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setInstallPrompt(e as BeforeInstallPromptEvent);
      console.log('App is installable, beforeinstallprompt event captured');
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Check for installation status changes
    const mediaQueryList = window.matchMedia('(display-mode: standalone)');
    mediaQueryList.addListener(checkInstallation);

    // Log service worker status
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistration()
        .then(registration => {
          if (registration) {
            console.log('Service Worker is registered');
            console.log('Service Worker scope:', registration.scope);
            console.log(
              'Service Worker state:',
              registration.active ? 'active' : 'inactive'
            );
          } else {
            console.log('No Service Worker is registered');
          }
        })
        .catch(error => {
          console.error('Error checking Service Worker registration:', error);
        });
    } else {
      console.log('Service Workers are not supported');
    }

    // Log browser information
    console.log('Browser:', navigator.userAgent);
    console.log(
      'Is Chrome:',
      /Chrome/.test(navigator.userAgent) &&
        !/Chromium/.test(navigator.userAgent)
    );
    // console.log('Is Brave:', navigator?.brave?.isBrave() || false);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
      mediaQueryList.removeListener(checkInstallation);
    };
  }, []);

  const handleInstallClick = async () => {
    if (installPrompt) {
      await installPrompt.prompt();
      const choiceResult = await installPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
        setIsInstalled(true);
      } else {
        console.log('User dismissed the install prompt');
      }
      setInstallPrompt(null);
    } else {
      console.log('Install prompt is not available');
    }
  };

  return {
    isInstallable: !!installPrompt && !isInstalled,
    handleInstallClick,
    isInstalled
  };
};
