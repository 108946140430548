import React from 'react';

interface HumanReadableTimeProps {
  date: Date;
}

interface Intervals {
  [key: string]: number; // Add index signature
}

const HumanReadableTime: React.FC<HumanReadableTimeProps> = ({ date }) => {
  const calculateHumanReadableTime = (timestamp: number): string => {
    const secondsAgo = Math.floor((Date.now() - timestamp) / 1000);

    const intervals: Intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
    };

    let counter: number;

    for (const unit in intervals) {
      counter = Math.floor(secondsAgo / intervals[unit]);

      if (counter > 0) {
        return `${counter} ${unit}${counter === 1 ? '' : 's'} ago`;
      }
    }

    return 'Just now';
  };

  return <span>{calculateHumanReadableTime(date.getTime())}</span>;
};

export default HumanReadableTime;
