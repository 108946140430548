import React, { useState } from 'react';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { MclientBreadcrumbItems } from 'data/commonData';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { setToken } from 'services/api/base';
import { CREATE_MERCHANT_CUSTOMER } from 'services/api/merchant/customer';
import { Merchant } from 'services/api/interface/response/Merchant';

const MerchantCreateClient = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    description: ''
  });
  const queries = useQueryClient();

  const createClientMutation = useMutation(
    'createClient',
    CREATE_MERCHANT_CUSTOMER,
    {
      onSuccess: () => {
        setIsSuccessful(true);
        queries.invalidateQueries('getCustomers');
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        setToken(token);
        setFormData({
          name: '',
          email: '',
          address: '',
          description: ''
        });
        navigate('/merchant/clients');
      },
      onError: (error: any) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('An error occurred');
        }
        console.error('Client creation error:', error);
      }
    }
  );

  const authUser = JSON.parse(localStorage.getItem('authUser') || '{}');
  const { merchant }: { merchant: Merchant } = authUser;

  const handleChange = (e: { target: { id: any; value: any } }) => {
    const { id, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [id]: value
    }));
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const customerPayload = {
      ...formData,
      password: 'password',
      merchant: { id: merchant.id },
      roles: ['customer']
    };
    setValidated(true);

    try {
      await createClientMutation.mutate(customerPayload);
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  return (
    <div className="mb-9">
      <PageBreadcrumb items={MclientBreadcrumbItems} />
      <div className="border-bottom mb-7 mx-n3 px-2 mx-lg-n6 px-lg-6">
        <Row>
          <Col xl={9}>
            <div className="d-sm-flex justify-content-between">
              <h2 className="mb-4">Create a new client</h2>
              <div className="d-flex mb-3">
                <Link to={'/merchant/clients'}>
                  <Button variant="phoenix-primary" className="me-2 px-6">
                    Cancel
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col xl={9}>
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <h4 className="mb-3">Client Information</h4>
            <Row className={'g-3 mb-5'}>
              <Col sm={6} md={6}>
                <FloatingLabel controlId="name" label="Client Name">
                  <Form.Control
                    type="text"
                    placeholder="Precious Organization"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={6}>
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    type="email"
                    placeholder="precious@gmail.com"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={6}>
                <FloatingLabel controlId="address" label="Address">
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <h4 className="mb-3">Description</h4>
            <FloatingLabel controlId="description" label="Client Description">
              <Form.Control
                as="textarea"
                style={{ height: '128px' }}
                type="text"
                placeholder="Client description"
                value={formData.description}
                onChange={handleChange}
              />
            </FloatingLabel>
            <div className="text-end">
              <Button
                variant="primary"
                type="submit"
                className="mt-6"
                disabled={createClientMutation.isLoading}
              >
                {createClientMutation.isLoading
                  ? 'Creating...'
                  : 'Create Client'}
              </Button>
            </div>
            {isSuccessful && (
              <p className="text-success">Client created successfully!</p>
            )}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default MerchantCreateClient;
