import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useState, useEffect } from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { GET_CAMPAIGNS_BY_AGENT_ID } from 'services/api/campaign';
import AgentssTable, {
  agentssTableColumns
} from 'components/tables/survey-app-table/agent/AgentTable';

const AgentCampaign = () => {
  const [agentId, setAgentId] = useState<string | null>(null);

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('authUser') || '{}');
    if (authUser?.agent?.id) {
      setAgentId(authUser.agent.id);
    }
  }, []);

  const {
    data: campaign,
    isLoading,
    isError
  } = useQuery(
    ['getCampaignsByAgentId', agentId],
    () => GET_CAMPAIGNS_BY_AGENT_ID(agentId!),
    {
      enabled: !!agentId,
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const table = useAdvanceTable({
    data: campaign?.data || [],
    columns: agentssTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <div className="mb-6">
        {isError && <div>Error loading campaigns</div>}
        <AdvanceTableProvider {...table}>
          <h2 className="mb-4">{campaign?.data?.length || 0} Campaign</h2>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="Search by name"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          {isLoading ? (
            <div className="g-3 mt-8 mb-8">
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
              </Placeholder>
            </div>
          ) : (
            <AgentssTable />
          )}
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default AgentCampaign;
