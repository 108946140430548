/* eslint-disable @typescript-eslint/no-empty-function */
import InstallPromptModal from 'components/modals/InstallPromptModal';
import SignInForm from 'components/modules/auth/SignInForm';
import { getItemFromStore } from 'helpers/utils';
import { useInstallPrompt } from 'hooks/useInstallPrompt';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { useAccessToken } from 'providers/AccessTokenProvider';
import { useAuthContext } from 'providers/AppProvider';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from 'services';
import { setToken } from 'services/api/base';
import { Role, roleMap } from 'sitemap';

const SignIn = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAgent, setIsAgent] = useState<boolean>(false);
  const {
    setRole,
    login: contextLogin,
    role,
    isAuthenticated
  } = useAuthContext();
  const { accessToken, updateAccessToken } = useAccessToken();
  const { isInstalled } = useInstallPrompt();

  const userRole = getItemFromStore('userRole');
  const isUserAuthenticated = getItemFromStore('isAuthenticated');

  const login = useMutation('login', LOGIN, {
    onSuccess: (data: any) => {
      setIsSuccessful(true);
      const {
        data: { accessToken, roles, ...authUser }
      } = data;

      contextLogin();
      if (roles.length > 0) {
        const userRole = roles[0];
        setRole(userRole);
        localStorage.setItem('userRole', userRole);
      }
      localStorage.setItem('authUser', JSON.stringify(authUser));
      localStorage.setItem('token', JSON.stringify(accessToken));
      updateAccessToken(accessToken);
      setToken(accessToken);
      setLoading(false);
    },
    onError: (error: any) => {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
      console.log(error);
    }
  });

  const handleRole = (role: Role) => {
    const path = roleMap[role as Role] || roleMap.default;
    if (role === 'agent') {
      !isInstalled ? setIsAgent(true) : navigate('/bio-auth');
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    if (isSuccessful && accessToken && isAuthenticated) {
      handleRole(role as unknown as Role);
    }
  }, [isSuccessful, accessToken, isAuthenticated, navigate]);

  useEffect(() => {
    if (userRole !== '' && userRole !== 'agent' && isUserAuthenticated) {
      handleRole(userRole);
    } else if (userRole == 'agent' && isUserAuthenticated) {
      !isInstalled ? setIsAgent(true) : navigate('/bio-auth');
    }
  });

  const handleLogin = async (
    email: string,
    password: string,
    location?: string
  ) => {
    await login.mutateAsync({ email, password, location });
  };
  return (
    <>
      <AuthCardLayout className="pb-md-7">
        <SignInForm
          layout="card"
          handleLogin={handleLogin}
          errorMessage={errorMessage}
          loading={loading}
          setLoading={setLoading}
        />
      </AuthCardLayout>
      <InstallPromptModal showModal={isAgent} />
    </>
  );
};

export default SignIn;
