import React, { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Agent } from 'services/api/interface/response/Agent';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import MerchantAgentModal from 'components/modals/MerchantAgentModal';
import PlacesSearchModal, {
  SelectedPlaceInterface
} from 'components/modals/PlacesAutoComplete';
import { useMutation, useQueryClient } from 'react-query';
import {
  ASSIGN_AGENT_LOCATION,
  UPDATE_ASSIGN_AGENT_LOCATION
} from 'services/api/merchant/campaign';
import { CampaignAgentLocation } from 'services/api/interface/response/CampaignAgentLocation';
import { AxiosResponse } from 'axios';
import { useCampaign } from 'providers/CampaignProvider';

export const MCampaignAgentTableColumns: ColumnDef<Agent>[] = [
  {
    accessorKey: 'name',
    header: 'Agent Name',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <div className="d-flex align-items-center">
          <div>{name}</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: {}, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="phone"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Phone</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return (
        <Link className="text-1000" to={`tel:${phone}`}>
          {phone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="email"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Email</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const email = original?.user?.email;
      return (
        <Link className="text-1000" to={`tel:${email}`}>
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'action',
    header: 'Assign Location',
    cell: ({ row: { original } }) => {
      const [showModel, setShowModel] = useState(false);
      const [isAssigning, setIsAssigning] = useState(false);
      const [agentLocation, setAgentLocation] =
        useState<CampaignAgentLocation | null>(null);
      const [agentPlace, setAgentSelectedPlace] =
        useState<SelectedPlaceInterface | null>(null);
      const { campaign } = useCampaign();
      const queries = useQueryClient();
      useEffect(() => {
        if (campaign?.campaignAgentLocations) {
          const agentLocation = campaign?.campaignAgentLocations.find(
            location => location.agentIds.includes(original.id)
          );
          setAgentLocation(agentLocation as CampaignAgentLocation);
        }
      }, [campaign]);
      const assignRequest = useMutation(
        'setAgentSelectedPlace',
        ASSIGN_AGENT_LOCATION,
        {
          onSuccess: (data: AxiosResponse<CampaignAgentLocation>) => {
            setShowModel(false);
            setIsAssigning(false);
            setAgentLocation(data.data);
            queries.invalidateQueries('getCampaign');
          },
          onError: () => {
            console.error('Agent Selected Error');
          },
          onMutate: () => {
            setIsAssigning(true);
            console.info('Agent Selected');
          }
        }
      );
      const updateAssignRequest = useMutation(
        'setAgentSelectedPlace',
        UPDATE_ASSIGN_AGENT_LOCATION,
        {
          onSuccess: (data: AxiosResponse<CampaignAgentLocation>) => {
            setShowModel(false);
            setIsAssigning(false);
            queries.invalidateQueries('getCampaign');
            setAgentLocation(data.data);
          },
          onError: () => {
            setIsAssigning(false);
          },
          onMutate: () => {
            setIsAssigning(true);
          }
        }
      );
      useEffect(() => {
        if (agentPlace) {
          if (agentLocation != null) {
            updateAssignRequest.mutateAsync({
              campaignAgentLocationId: agentLocation?.id,
              agents: {
                id: original.id
              },
              campaignId: campaign?.id as string,
              location: agentPlace,
              address: agentPlace.adr_address as string
            });
          } else {
            assignRequest.mutateAsync({
              agents: {
                id: original.id
              },
              campaignId: campaign?.id as string,
              location: agentPlace,
              address: agentPlace.adr_address as string
            });
          }
        }
      }, [agentPlace, setAgentSelectedPlace]);
      return (
        <>
          <Button
            variant="primary"
            className="me-4"
            startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
            onClick={() => setShowModel(true)}
          >
            {!agentLocation ? 'Add Location' : 'Change Agent Location'}
          </Button>
          {agentLocation && (
            <p>
              Agent is assigned to{' '}
              <strong>{agentLocation?.location.localGovernmentArea} </strong>
              Local Government Area
            </p>
          )}
          {showModel && (
            <>
              <PlacesSearchModal
                show={showModel}
                handleClose={() => setShowModel(false)}
                data={original}
                setAgentSelectedPlace={setAgentSelectedPlace}
                isAssigning={isAssigning}
              />
            </>
          )}
        </>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap ps-4' }
    }
  }
];

const MCampaignAgentTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default MCampaignAgentTable;
