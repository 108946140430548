import React, { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Agent } from 'services/api/interface/response/Agent';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PlacesSearchModal, {
  SelectedPlaceInterface
} from 'components/modals/PlacesAutoComplete';
import { useMutation } from 'react-query';
import { ASSIGN_AGENT_LOCATION } from 'services/api/merchant/campaign';

export const campaignAgentTableColumns: ColumnDef<Agent>[] = [
  {
    accessorKey: 'name',
    header: 'Agent Name',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return (
        <div className="d-flex align-items-center">
          <div>{name}</div>
        </div>
      );
    },
    meta: {
      headerProps: { style: {}, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-100 rounded me-2">
            <FeatherIcon
              icon="phone"
              size={16}
              className="text-primary-600 dark__text-primary-300"
            />
          </div>
          <span>Phone</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return (
        <Link className="text-1000" to={`tel:${phone}`}>
          {phone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold ps-4 border-end'
      }
    }
  },
  {
    accessorKey: 'action',
    header: 'Assign Location',
    cell: ({ row: { original } }) => {
      const [showModel, setShowModel] = useState(false);
      const [isAssigning, setIsAssigning] = useState(false);
      const [agentPlace, setAgentSelectedPlace] =
        useState<SelectedPlaceInterface | null>(null);
      const { id: campaignId } = useParams<{ id: string }>();
      const assignRequest = useMutation(
        'setAgentSelectedPlace',
        ASSIGN_AGENT_LOCATION,
        {
          onSuccess: () => {
            setShowModel(false);
          },
          onError: () => {
            console.error('Agent Selected Error');
          },
          onMutate: () => {
            setIsAssigning(true);
            console.info('Agent Selected');
          }
        }
      );

      useEffect(() => {
        if (agentPlace) {
          assignRequest.mutateAsync({
            agents: {
              id: original.id
            },
            campaignId: campaignId as string,
            location: agentPlace,
            address: agentPlace.adr_address as string
          });
        }
      }, [agentPlace, setAgentSelectedPlace]);

      return (
        <>
          <Button
            variant="primary"
            className="me-4"
            startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
            onClick={() => setShowModel(true)}
          >
            Add Location
          </Button>

          {showModel && (
            <>
              <PlacesSearchModal
                show={showModel}
                handleClose={() => setShowModel(false)}
                data={original}
                setAgentSelectedPlace={setAgentSelectedPlace}
                isAssigning={isAssigning}
              />
            </>
          )}
        </>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap ps-4' }
    }
  }
];

const CampaignAgentTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default CampaignAgentTable;
