import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Client } from 'data/crm/survey-app-types/Client';
import { faLocation, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DELETE_CUSTOMER } from 'services/api/customer';

const ClientCard = ({ clients }: { clients: Client }) => {
  const handleDelete = async (customerId: number) => {
    try {
      await DELETE_CUSTOMER(customerId.toString());
      window.location.reload();
    } catch (error) {
      console.error('Error deleting customer:', error);
    }
  };
  return (
    <Card>
      <Card.Body>
        <div className="border-bottom">
          <div className="d-flex align-items-start mb-1">
            <Form.Check type="checkbox" />
            <div className="d-sm-flex align-items-center ps-3">
              <Link
                to={`/admin/view-client/${clients.id}`}
                className="fw-bold fs-7 lh-sm line-clamp-1 me-sm-4"
              >
                {clients.name}
              </Link>
            </div>
          </div>
          <p className="fs-9 fw-semi-bold text-900 ms-4 text mb-4 ps-2">
            {clients.description}
          </p>
        </div>
        <Row className="g-1 g-sm-3 mt-2 lh-1">
          <Col
            sm="auto"
            className="flex-1 text-danger cursor-pointer"
            onClick={() => handleDelete(clients.id)}
          >
            <FontAwesomeIcon icon={faTrash} className="me-2" />
            Delete
          </Col>
          <Col sm="auto">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faLocation} className="me-2" />
              <p className="mb-0 fs-9 fw-semi-bold text-700">
                {clients.address}
              </p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ClientCard;
