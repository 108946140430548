import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { Card, Col, Row } from 'react-bootstrap';
import { viewClientBreadcrumbItems } from 'data/commonData';
import { GET_CUSTOMER_BY_ID } from 'services/api/customer';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const ViewClient = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    data: client,
    isFetching
    // isSuccess
  } = useQuery(
    ['getMerchantDetails', id],
    () => (id ? GET_CUSTOMER_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );
  return (
    <div className="pb-9">
      <PageBreadcrumb items={viewClientBreadcrumbItems} />
      <h2 className="mb-4 mb-lg-6">Clients Details</h2>
      {isFetching ? (
        <h1>Loading....</h1>
      ) : (
        <Row className="">
          <Col md={10} className="mb-5">
            <Card className="h-100">
              <Card.Body className="">
                <div className="mb-3">
                  <h3 className="me-1">{client?.data.name}</h3>
                </div>
                <Row>
                  <Col md={6} className="mb-3">
                    <h5 className="text-800">Address</h5>
                    <p className="text-800">{client?.data.address}</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={10} className="">
            <h2 className="mb-3">About this Client</h2>
            <p className="text-justify text-800 mb-6 mb-xxl-8">
              {client?.data.description}
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ViewClient;
