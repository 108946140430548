import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie, UilCube } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'home',
        icon: 'pie-chart',
        active: true,
        hasNew: true,
        flat: true,
        pages: [
          {
            name: 'e-commerce',
            path: '/',
            pathName: 'default-dashboard',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'project-management',
            path: '/dashboard/project-management',
            pathName: 'project-management-dashbaord',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'CRM',
            path: '/dashboard/crm',
            pathName: 'crm',
            topNavIcon: 'phone',
            active: true,
            new: true
          },
          {
            name: 'social-feed',
            path: '/apps/social/feed',
            pathName: 'social-feed',
            topNavIcon: 'share-2',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'apps',
    icon: UilCube,
    pages: [
      {
        name: 'e-commerce',
        active: true,
        icon: 'shopping-cart',
        pages: [
          {
            name: 'admin',
            active: true,
            pages: [
              {
                name: 'add-product',
                path: '/apps/e-commerce/admin/add-product',
                pathName: 'e-commerce-add-product',
                active: true
              },
              {
                name: 'products',
                path: '/apps/e-commerce/admin/products',
                pathName: 'e-commerce-products',
                active: true
              },
              {
                name: 'customers',
                path: '/apps/e-commerce/admin/customers',
                pathName: 'e-commerce-customers',
                active: true
              },
              {
                name: 'customer-details',
                path: '/apps/e-commerce/admin/customer-details',
                pathName: 'e-commerce-customer-details',
                active: true
              },
              {
                name: 'orders',
                path: '/apps/e-commerce/admin/orders',
                pathName: 'e-commerce-orders',
                active: true
              },
              {
                name: 'order-details',
                path: '/apps/e-commerce/admin/order-details',
                pathName: 'e-commerce-order-details',
                active: true
              },
              {
                name: 'refund',
                path: '/apps/e-commerce/admin/refund',
                pathName: 'e-commerce-refund',
                active: true
              }
            ]
          },
          {
            name: 'customer',
            active: true,
            pages: [
              {
                name: 'homepage',
                path: 'apps/e-commerce/customer/homepage',
                pathName: 'e-commerce-fe-home',
                active: true
              },
              {
                name: 'product-details',
                path: 'apps/e-commerce/customer/product-details',
                pathName: 'e-commerce-fe-product-details',
                active: true
              },
              {
                name: 'products-filter',
                path: 'apps/e-commerce/customer/products-filter',
                pathName: 'e-commerce-fe-product-filter',
                active: true
              },
              {
                name: 'cart',
                path: 'apps/e-commerce/customer/cart',
                pathName: 'e-commerce-fe-cart',
                active: true
              },
              {
                name: 'checkout',
                path: 'apps/e-commerce/customer/checkout',
                pathName: 'e-commerce-fe-checkout',
                active: true
              },
              {
                name: 'shipping-info',
                path: 'apps/e-commerce/customer/shipping-info',
                pathName: 'e-commerce-fe-shipping-info',
                active: true
              },
              {
                name: 'profile',
                path: 'apps/e-commerce/customer/profile',
                pathName: 'e-commerce-fe-profile',
                active: true
              },
              {
                name: 'favorite-stores',
                path: 'apps/e-commerce/customer/favorite-stores',
                pathName: 'e-commerce-fe-favorite-stores',
                active: true
              },
              {
                name: 'wishlist',
                path: 'apps/e-commerce/customer/wishlist',
                pathName: 'e-commerce-fe-wishlist',
                active: true
              },
              {
                name: 'order-tracking',
                path: 'apps/e-commerce/customer/order-tracking',
                pathName: 'e-commerce-fe-order-tracking',
                active: true
              },
              {
                name: 'invoice',
                path: 'apps/e-commerce/customer/invoice',
                pathName: 'e-commerce-fe-invoice',
                active: true
              }
            ]
          }
        ]
      },
      {
        name: 'CRM',
        icon: 'phone',
        active: true,
        new: true,
        pages: [
          {
            name: 'analytics',
            path: '/apps/crm/analytics',
            pathName: 'crm-analytics',
            active: true
          },
          {
            name: 'deals',
            path: '/apps/crm/deals',
            pathName: 'crm-deals'
          },
          {
            name: 'deal-details',
            path: '/apps/crm/deal-details',
            pathName: 'crm-deal-details',
            active: true
          },
          {
            name: 'leads',
            path: '/apps/crm/leads',
            pathName: 'crm-leads',
            active: true
          },
          {
            name: 'lead details',
            path: '/apps/crm/lead-details',
            pathName: 'crm-lead-details',
            active: true
          },
          {
            name: 'reports',
            path: '/apps/crm/reports',
            pathName: 'crm-reports',
            active: true
          },
          {
            name: 'report details',
            path: '/apps/crm/report-details',
            pathName: 'crm-report-details',
            active: true
          },
          {
            name: 'add-contact',
            path: '/apps/crm/add-contact',
            pathName: 'crm-add-contact',
            active: true
          }
        ]
      },
      {
        name: 'project-management',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'create new',
            path: '/apps/project-management/create-new',
            pathName: 'project-management-create-new',
            active: true
          },
          {
            name: 'project-list-view',
            path: '/apps/project-management/project-list-view',
            pathName: 'project-management-project-list-view',
            active: true
          },
          {
            name: 'project-card-view',
            path: '/apps/project-management/project-card-view',
            pathName: 'project-management-project-card-view',
            active: true
          },
          {
            name: 'project-board-view',
            path: '/apps/project-management/project-board-view',
            pathName: 'project-management-project-board-view',
            active: true
          },
          {
            name: 'todo-list',
            path: '/apps/project-management/todo-list',
            pathName: 'project-management-todo-list',
            active: true
          },
          {
            name: 'project-details',
            path: '/apps/project-management/project-details',
            pathName: 'project-management-project-details',
            active: true
          }
        ]
      },
      {
        name: 'chat',
        icon: 'message-square',
        path: '/apps/chat',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'email',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'inbox',
            path: '/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'email-detail',
            path: '/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'compose',
            path: '/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'events',
        icon: 'bookmark',
        active: true,
        pages: [
          {
            name: 'create an event',
            path: '/apps/events/create-an-event',
            pathName: 'create-an-event',
            active: true
          },
          {
            name: 'Event detail',
            path: '/apps/events/event-detail',
            pathName: 'event-detail',
            active: true
          }
        ]
      },
      {
        name: 'social',
        icon: 'share-2',
        active: true,
        pages: [
          {
            name: 'profile',
            path: '/apps/social/profile',
            pathName: 'social-profile',
            active: true
          },
          {
            name: 'settings',
            path: '/apps/social/settings',
            pathName: 'settings',
            active: true
          }
        ]
      },
      {
        name: 'calendar',
        icon: 'calendar',
        path: 'apps/calendar',
        pathName: 'app-calendar'
      }
    ]
  }
];

export const adminRoutes: RouteItems[] = [
  {
    label: 'apps',
    icon: UilCube,
    pages: [
      {
        name: 'Dashboard',
        icon: 'home',
        path: '/admin',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Merchants',
        icon: 'package',
        path: '/admin/merchant',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Client/Organizations',
        icon: 'bar-chart-2',
        path: '/admin/clients',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Agents',
        icon: 'users',
        path: '/admin/agents',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Campaign',
        icon: 'bell',
        path: '/admin/campaign',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Settings',
        icon: 'settings',
        path: '/admin/settings',
        pathName: 'app-chat',
        active: true
      }
    ]
  }
];

export const merchantRoutes: RouteItems[] = [
  {
    label: 'apps',
    icon: UilCube,
    pages: [
      {
        name: 'Dashboard',
        icon: 'home',
        path: '/merchant',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Client/Organizations',
        icon: 'bar-chart-2',
        path: '/merchant/clients',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Agents',
        icon: 'users',
        path: '/merchant/agents',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Campaign',
        icon: 'bell',
        path: '/merchant/campaign',
        pathName: 'app-chat',
        active: true
      },
      {
        name: 'Settings',
        icon: 'settings',
        path: '/merchant/settings',
        pathName: 'app-chat',
        active: true
      }
    ]
  }
];

export const agentRoutes: RouteItems[] = [
  {
    label: 'navbar',
    icon: UilCube,
    active: true,
    pages: [
      {
        name: 'Campaign',
        icon: 'bell',
        path: '/agent',
        pathName: 'campaign',
        active: true
      },
      {
        name: 'Results',
        icon: 'message-square',
        path: '/agent/results',
        pathName: 'results',
        active: true
      }
    ]
  }
];

export const customerRoutes: RouteItems[] = [
  {
    label: 'navbar',
    icon: UilCube,
    active: true,
    pages: [
      {
        name: 'Results',
        icon: 'message-square',
        path: '/customer/results',
        pathName: 'results',
        active: true
      }
    ]
  }
];
export type Role = 'admin' | 'merchant' | 'agent' | 'customer';

interface RoleMap {
  admin: string;
  merchant: string;
  customer: string;
  default: string;
  agent?: string;
}

export const roleMap: RoleMap = {
  admin: '/admin',
  merchant: '/merchant',
  customer: '/customer',
  default: '/'
};
