import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useEffect, useState } from 'react';
// import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Alert, Form, Spinner } from 'react-bootstrap';

const SignInForm = ({
  layout,
  handleLogin,
  errorMessage,
  loading = false,
  setLoading
}: {
  layout: 'simple' | 'card' | 'split';
  handleLogin: (email: string, password: string, location?: string) => void;
  errorMessage: string;
  loading?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [location, setLocation] = useState<string>('');
  const [isAgent, setIsAgent] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<string>('');

  useEffect(() => {
    if (errorMessage !== '') {
      setShowErrorMessage(true);
      setLoading(false);
    }
  }, [errorMessage]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (isAgent && !location) {
        setLocationError('Agents must provide their location to log in.');
        return;
      }
      setLoading(true);
      handleLogin(email, password, isAgent ? location : undefined);
    }
    setValidated(true);
  };

  const requestUserCurrentLocation = (e: any) => {
    const newAgentStatus = !isAgent;
    setIsAgent(newAgentStatus);
    setLocationError('');
    console.log(e);
    if (newAgentStatus) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { longitude, latitude } = position.coords;
            const locationString = `${latitude},${longitude}`;
            setLocation(locationString);
          },
          error => {
            console.error('Error getting location:', error.message);
            setLocationError(
              `Unable to retrieve location. Please try again. ${error.message}`
            );
            setLocation('');
          },
          {
            enableHighAccuracy: true
          }
        );
      } else {
        setLocationError('Geolocation is not supported by your browser.');
        setLocation('');
      }
    } else {
      setLocation('');
    }
  };

  return (
    <>
      <div className="text-center mb-7" title={layout}>
        <h3 className="text-1000">Sign In</h3>
        <p className="text-700">Get access to your account</p>
      </div>
      {/* <AuthSocialButtons title="Sign in" /> */}
      <div className="position-relative">
        <hr className="bg-200 mt-5 mb-4" />
        <div className="divider-content-center">Use Email & Password</div>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              required
              id="email"
              type="email"
              value={email}
              className="form-icon-input"
              placeholder="name@example.com"
              onChange={e => setEmail(e.target.value)}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid Email.
            </Form.Control.Feedback>
            <FontAwesomeIcon
              icon={faUser}
              className="text-900 fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Password</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              required
              id="password"
              type="password"
              value={password}
              className="form-icon-input"
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
              disabled={loading}
            />
            <Form.Control.Feedback type="invalid">
              Password is required
            </Form.Control.Feedback>
            <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
          </div>
        </Form.Group>
        <Form.Check type="checkbox" className="mb-3 mt-3">
          <Form.Check.Input
            type="checkbox"
            name="location"
            id="location"
            checked={isAgent}
            onChange={requestUserCurrentLocation}
          />
          <Form.Check.Label htmlFor="termsService" className="fs-9 text-none">
            I am an Agent
          </Form.Check.Label>
        </Form.Check>
        {isAgent && location && (
          <p className="text-muted mb-3">Current location is set</p>
        )}
        {locationError && <p className="text-danger mb-3">{locationError}</p>}
        <Button
          type="submit"
          variant="primary"
          className="w-100 mb-3"
          disabled={loading || (isAgent && !location)}
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{' '}
              Signing In...
            </>
          ) : (
            'Sign In'
          )}
        </Button>
        <div className="text-center"></div>
      </Form>
      {showErrorMessage && (
        <Alert
          variant="danger"
          className="text-white"
          onClose={() => setShowErrorMessage(false)}
          dismissible
        >
          <p>{errorMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default SignInForm;
