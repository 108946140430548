import React, { useState } from 'react';
import { Button, Card, Col, Form, Placeholder, Row } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSelect from 'components/base/ReactSelect';
import { GET_CAMPAIGN_BY_ID } from 'services/api/campaign';
import { ADD_CAMPAIGN_AGENTS } from 'services/api/campaign'; // Update the import path as needed
import { GET_ALL_MERCHANT_AGENTS } from 'services/api/merchant/agent';

interface Option {
  value: any;
  label: string;
}

interface SelectedAgent {
  id: string;
}

const AddAgent = () => {
  const { id } = useParams<{ id?: string }>();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const queries = useQueryClient();
  const { data: campaign, isFetching: campaignFetching } = useQuery(
    ['getCampaignDetails', id],
    () => (id ? GET_CAMPAIGN_BY_ID(id) : null),
    {
      staleTime: 3 * 60 * 60 * 1000
    }
  );

  const { data: agents } = useQuery('getAgents', GET_ALL_MERCHANT_AGENTS, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const merchantOptions: Option[] = agents?.data
    ? agents.data.map((agent: { id: any; name: any }) => ({
        value: agent.id,
        label: agent.name
      }))
    : [];

  const [selectedAgents, setSelectedAgents] = useState<Option[]>([]);

  const handleAgentSelection = (selectedOptions: Option[] | null | unknown) => {
    if (selectedOptions) {
      console.log('Loving selected agents');
      setSelectedAgents(selectedOptions as Option[]);
    } else {
      setSelectedAgents([]);
    }
  };

  const mutation = useMutation(
    (newAgents: SelectedAgent[]) => ADD_CAMPAIGN_AGENTS(id!, newAgents),
    {
      onSuccess: () => {
        setIsSuccessful(true);
        navigate('/merchant/campaign');
        queries.invalidateQueries('getCampaigns');
      },
      onError: (error: any) => {
        setErrorMessage(error.response.data.message);
        console.error('Error adding agents:', error);
      }
    }
  );

  const handleSubmit = async () => {
    const selectedAgentsArray: SelectedAgent[] = selectedAgents.map(agent => ({
      id: agent.value
    }));

    try {
      await mutation.mutateAsync(selectedAgentsArray);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
      {campaignFetching ? (
        <Placeholder />
      ) : (
        <>
          <Row className="">
            <Col md={10} className="mb-5">
              <Card className="h-100">
                <Card.Body className="">
                  <div className="mb-3">
                    <h3 className="me-1">{campaign?.data.name}</h3>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Form>
            <Row className="mt-5 mb-5">
              <Form.Group as={Col} md="10" controlId="agents">
                <Form.Label>Select Agents</Form.Label>
                <ReactSelect
                  options={merchantOptions}
                  isMulti
                  placeholder="Select Agents"
                  onChange={handleAgentSelection}
                />
              </Form.Group>
            </Row>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? 'Submitting...' : 'Submit'}
            </Button>
            {isSuccessful && (
              <p className="text-success">Agents created successfully!</p>
            )}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        </>
      )}
    </div>
  );
};

export default AddAgent;
