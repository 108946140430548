// src/components/CreateCampaign.tsx

import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CreateForm from './CreateCampaignForm'; // Remove Field import
import { Link } from 'react-router-dom';
import { CampaignRequest } from 'services/api/interface/request/CampaignRequest';
import { getItemFromStore, setItemToStore } from 'helpers/utils';
import { useQuery, useQueryClient } from 'react-query';
import { GET_CUSTOMERS_QUERY } from 'services/api/customer';
import { GET_ALL_MERCHANTS } from 'services/api/merchant';

interface Option {
  value: any;
  label: string;
}

const AdminCreateCampaign = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);

  const [campaign, setCampaign] = useState<Omit<
    CampaignRequest,
    'id' | 'createdAt' | 'updatedAt'
  > | null>(getItemFromStore('notSubmittedCampaign') || null);
  const [campaignName, setCampaignName] = useState(campaign?.name || '');
  const [client, setClient] = useState(campaign?.customer?.id || '');
  const [merchant, setMerchant] = useState(campaign?.merchant?.id || '');
  const [description, setDescription] = useState(campaign?.description || '');

  const { data: clients } = useQuery('getCustomer', GET_CUSTOMERS_QUERY, {
    staleTime: 3 * 60 * 60 * 1000
  });
  const { data: merchants } = useQuery('getadminMerchant', GET_ALL_MERCHANTS, {
    staleTime: 3 * 60 * 60 * 1000
  });

  const queryClient = useQueryClient();

  const handleSubmit = async (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // await createCampaignMutation.mutate({});
      const campaignData = {
        customer: { id: client },
        merchant: { id: merchant },
        name: campaignName,
        description,
        formFields: []
      };
      setItemToStore('notSubmittedCampaign', JSON.stringify(campaignData));
      queryClient.invalidateQueries('getCampaigns');
      setIsSuccessful(true);
      setCampaign(campaignData);
      setErrorMessage('');
      setCampaignName('');
      setClient('');
      setMerchant('');
      setDescription('');
      setIsLoading(false);

      setValidated(false);
    }
    setValidated(true);
    setIsLoading(false);
  };

  const handleProceed = () => {
    setShowCreateCampaignForm(true);
  };

  const clientOptions: Option[] = clients?.data
    ? clients.data.map((client: { id: any; name: any }) => ({
        value: client.id,
        label: client.name
      }))
    : [];

  const merchantOptions: Option[] = merchants?.data
    ? merchants.data.map((merchant: { id: any; name: any }) => ({
        value: merchant.id,
        label: merchant.name
      }))
    : [];

  return (
    <div>
      {!showCreateCampaignForm && (
        <>
          <Link to={'/admin/campaign'}>
            <Button variant="secondary" className="mb-5">
              Back
            </Button>
          </Link>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="10" controlId="exampleMerchantName">
                <Form.Label>Campaign name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="John Doe"
                  value={campaignName}
                  onChange={e => setCampaignName(e.target.value)}
                  disabled={isSuccessful}
                />
                <Form.Control.Feedback type="invalid">
                  Campaign name is required
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="10" controlId="email">
                <Form.Label>Client</Form.Label>
                <Form.Control
                  as="select"
                  value={client}
                  onChange={e => setClient(e.target.value)}
                  required
                  disabled={isSuccessful}
                >
                  <option value="">Select Clients...</option>
                  {clientOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="10" controlId="email">
                <Form.Label>Merchant</Form.Label>
                <Form.Control
                  as="select"
                  value={merchant}
                  onChange={e => setMerchant(e.target.value)}
                  required
                  disabled={isSuccessful}
                >
                  <option value="">Select Merchant...</option>
                  {merchantOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="10" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ height: '100px' }}
                  type="text"
                  placeholder="Description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  disabled={isSuccessful}
                />
              </Form.Group>
            </Row>
            {!isSuccessful && (
              <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            )}
            {isSuccessful && (
              <div className="mt-3">
                <Button onClick={handleProceed}>Proceed</Button>
              </div>
            )}
            {isSuccessful && (
              <p className="text-success">Campaign created successfully!</p>
            )}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        </>
      )}
      {showCreateCampaignForm && (
        <CreateForm campaign={campaign as CampaignRequest} />
      )}{' '}
    </div>
  );
};

export default AdminCreateCampaign;
