import { fieldTrackerAPI } from './base';

export const GET_ADDRESS_QUERY = async ({
  latitude,
  longitude
}: {
  latitude: number;
  longitude: number;
}): Promise<any> => {
  const response = await fieldTrackerAPI.get(
    `/users/getAddress?latitude=${latitude}&longitude=${longitude}`
  );
  return response.data;
};
